import React from "react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PANEL_MODES } from "../consts";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    // padding: `${theme.spacing(3)} ${theme.spacing(1.5)} 0 ${theme.spacing(2)}`,
    backgroundColor: theme.palette.grey.paper,
    paddingInlineStart: theme.spacing(2),
    paddingBlock: theme.spacing(1.2)
  },
  tab: {
    flex: 1,
    textTransform: "none",
    borderRadius: theme.shape.borderRadius,
    fontWeight: 400,
    padding: theme.spacing(0.75, 1),
    minWidth: "133px",
    color: theme.palette.grey.toggleGrey,
    border: `1px solid #90CAF9`,
    backgroundColor: theme.palette.blue.babyLight
  },
  activeTab: {
    backgroundColor: "#90CAF9",
    color: "#48485D",
    border: "none",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },

  activeIcon: {
    backgroundColor: "#48485D",
    color: theme.palette.primary.contrastText
  },
  leftButton: {
    left: 5,
    zIndex: 100
  },
  rightButton: {
    right: 5,
    zIndex: 100
  }
}));

const TabNavigation = ({ activeMode, onModeChange }) => {
  const classes = useStyles();
  console.log(activeMode);
  return (
    <Box className={classes.container}>
      <Button
        className={`${classes.tab} ${activeMode === PANEL_MODES.HIGHLIGHTS ? clsx(classes.activeTab, classes.leftButton) : ""} `}
        onClick={() => onModeChange(PANEL_MODES.HIGHLIGHTS)}
        disableElevation
        variant={activeMode === PANEL_MODES.HIGHLIGHTS ? "contained" : "text"}>
        Highlights
      </Button>

      <Button
        className={`${classes.tab} ${activeMode === PANEL_MODES.ANSWER ? clsx(classes.activeTab, classes.rightButton) : ""}`}
        onClick={() => onModeChange(PANEL_MODES.ANSWER)}
        disableElevation
        variant={activeMode === PANEL_MODES.ANSWER ? "contained" : "text"}>
        Answer
      </Button>
    </Box>
  );
};

export default TabNavigation;
