import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { Box, Button, Chip, InputBase, keyframes, styled } from "@mui/material";
import { RichTooltipPopover } from "../../SharedComponents/RichTooltipPopover";

const HoverDeleteChip = styled(Chip)(() => ({
  margin: "4px 4px 4px 0",
  "& .MuiChip-deleteIcon": {
    display: "none"
  },
  "&:hover .MuiChip-deleteIcon": {
    display: "block"
  }
}));

const AddChip = ({ onAdd }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState("");
  const inputRef = useRef(null);

  // Focus the input when entering edit mode
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // Handle click outside to cancel editing
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isEditing &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsEditing(false);
        setValue("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  const handleChipClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && value.trim()) {
      onAdd(value.trim());
      setValue("");
      setIsEditing(false);
    } else if (event.key === "Escape") {
      setIsEditing(false);
      setValue("");
    }
  };

  return (
    <Chip
      variant={isEditing ? "outlined" : "filled"}
      color="primary"
      sx={{ margin: "4px" }}
      onClick={handleChipClick}
      label={
        isEditing ? (
          <Box>
            <InputBase
              inputRef={inputRef}
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Type and press Enter"
              autoFocus
              slotProps={{
                input: {
                  sx: {
                    fontSize: "12px"
                  }
                }
              }}
            />
          </Box>
        ) : (
          "+ add"
        )
      }
      size="small"
    />
  );
};

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
`;

function JourneyButtons({
  quotes,
  // onQuotes,
  concepts,
  onConcepts,
  disabled,
  error,
  blink
}) {
  // Hooks
  const intl = useIntl();

  //Variables
  const handleKeyConcepts = (concept) => {
    if (concepts.includes(concept)) {
      concepts = concepts.filter((item) => item !== concept);
    } else {
      concepts = [...concepts, concept];
    }
    onConcepts(concepts);
  };

  const blinkStyle = blink
    ? {
        animation: `${blinkAnimation} 1.5s ease infinite`
      }
    : {};

  // Render
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "12px"
      }}>
      <Button
        variant="outlined"
        color={error ? "error" : "primary"}
        sx={{ minHeight: "37px", ...blinkStyle }}
        disabled={disabled}
        // onClick={() => setSaveAndLeave(true)}
      >
        {`${quotes.length} ${intl.formatMessage({
          id: "task.create.assignment.journey.milestonesHighlights",
          defaultMessage: "Milestones Highlights"
        })}`}
      </Button>
      <RichTooltipPopover
        title="task.create.assignment.journey.keyConceptsTitle"
        bodyHtml={
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
              width: "100%",
              paddingRight: "20px",
              marginTop: "8px",
              marginBottom: "4px"
            }}>
            {concepts.map((concept) => (
              <HoverDeleteChip
                key={concept}
                label={concept}
                variant="outlined"
                color="primary"
                size="small"
                onDelete={() => handleKeyConcepts(concept)}
              />
            ))}
            <AddChip onAdd={handleKeyConcepts} />
          </Box>
        }
        style={{ width: "400px" }}
        triggerElement={
          <Button
            variant="outlined"
            sx={{ minHeight: "37px", ...blinkStyle }}
            disabled={disabled}>
            {`${concepts.length} ${intl.formatMessage({
              id: "task.create.assignment.journey.keyConcepts",
              defaultMessage: "Key Concepts Evaluation"
            })}`}
          </Button>
        }
      />
    </Box>
  );
}

JourneyButtons.propTypes = {
  text: PropTypes.object,
  highlights: PropTypes.array,
  readOnly: PropTypes.bool,
  concepts: PropTypes.array,
  onConcepts: PropTypes.func,
  interactionSubtype: PropTypes.string,
  tempId: PropTypes.string,
  onUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default JourneyButtons;
