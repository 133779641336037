import React from "react";
import { PanelResizeHandle } from "react-resizable-panels";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

const useStyles = makeStyles((theme) => ({
  resizeHandleOuter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "4px",
    position: "relative",
    outline: "none",
    "&:hover $dotHandle": {
      backgroundColor: theme.palette.primary.light
    },
    "&:active $dotHandle": {
      backgroundColor: theme.palette.primary.main
    }
  },
  touchResizeHandle: {
    width: "12px"
  },
  dotHandle: {
    width: "8px",
    height: "40px",
    backgroundColor: theme.palette.divider,
    borderRadius: "10px",
    position: "absolute",
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  dot: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white
  },
  invisibleDragArea: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    cursor: "col-resize"
  },
  left: { marginRight: theme.spacing(2) },
  right: {
    marginLeft: theme.spacing(2)
  }
}));

const CustomResizeHandle = ({ isTouchDevice, panelSide, ...props }) => {
  const classes = useStyles();
  const device = useDeviceDetect();

  return (
    <PanelResizeHandle
      {...props}
      className={clsx(
        `${classes.resizeHandleOuter} ${
          isTouchDevice && classes.touchResizeHandle
        } ${classes[panelSide]}`
      )}>
      {/* Invisible full-height drag area */}
      {device.isTouchDevice && (
        <>
          <div className={classes.dotHandle}>
            <span className={classes.dot} />
            <span className={classes.dot} />
            <span className={classes.dot} />
          </div>
          <div className={classes.invisibleDragArea} />
        </>
      )}

      {/* Visible dot handle */}
    </PanelResizeHandle>
  );
};

export default CustomResizeHandle;
