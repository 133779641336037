import React, { useEffect, useRef, useState } from "react";
import { USER_TYPE } from "../../../../consts.js";
import {
  outgoingMessage,
  CONVERSATION_TYPES,
  selectConversation,
  selectIsLoading
} from "../../../../redux/chatSlice.js";
import { useDispatch, useSelector } from "react-redux";
// Components
import { ScrollBox } from "../../../SharedComponents/index.js";
import { ChatActivityRecorder } from "../../../chat/ChatActivityRecorder.jsx";
// Material UI
import {
  Paper,
  Box,
  Grow,
  useTheme,
  TextField,
  IconButton
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import makeStyles from "@mui/styles/makeStyles";
import NewChatBubble from "./NewChatBubble.jsx";
import BouncingDots from "../../../SharedComponents/BouncingDots.jsx";
import { setPersistent } from "../../../../redux/userSlice.js";
import { useStepStage } from "../../../../hooks/index.js";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    chatBox: {
      minHeight: "50vh",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: theme.palette.grey.paper
    },
    headerAction: {
      display: "flex"
    },
    main: {
      display: "flex",
      paddingBlockStart: theme.spacing(1),
      paddingInline: theme.spacing(2.5),
      flexGrow: 1,
      overflow: "auto",
      minHeight: 0
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      maxHeight: 332,
      height: "auto",
      paddingInline: `${theme.spacing(2)} ${theme.spacing(4.5)}`,
      paddingBlock: `${theme.spacing(1.5)} ${theme.spacing(0.5)}`,
      flexShrink: 0
    },
    input: {
      width: "100%",
      "& .MuiInputBase-root": {
        padding: "4px"
      },
      "& fieldset": {
        borderWidth: "0"
      },
      "& .Mui-focused fieldset": {
        borderWidth: "0 !important"
      },
      "& .MuiInputBase-input::placeholder": {
        color: theme.palette.text.secondary,
        opacity: 1
      }
    }
  };
});
function NewChat() {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const chatBoxRef = useRef();
  const chatContentRef = useRef();
  const [step] = useStepStage();
  const persistent = useSelector((state) => state.user.persistent);
  // Ephemeral state
  const [message, setMessage] = useState("");
  // Redux Selectors
  const conversation = useSelector((state) => selectConversation(state));
  const isLoading = useSelector((state) => selectIsLoading(state));
  // Ephermeral state
  const [activeChat, setActiveChat] = useState(false);

  // Derived state
  const course_id =
    conversation && conversation.length && conversation[0].course_id;
  const task_id =
    conversation && conversation.length && conversation[0].task_id;
  const text_id =
    conversation && conversation.length && conversation[0].text_id;
  const question_id =
    conversation && conversation.length && conversation[0].interaction_id;
  const session_id = conversation && conversation.length && conversation[0].id;
  // Behavior

  useEffect(() => {
    setActiveChat(
      !!conversation.filter((a) => a.user_type === USER_TYPE.STUDENT).length
    );
  }, [conversation]);

  useEffect(() => {
    if (!chatContentRef.current) return;

    setTimeout(() => {
      scrollChatToBottom();
    }, 100);
  }, [conversation, isLoading]);

  function scrollChatToBottom() {
    const targetNode = chatContentRef.current;
    if (
      targetNode &&
      targetNode.scrollTop + targetNode.clientHeight !== targetNode.scrollHeight
    ) {
      targetNode.scrollChatToBottom();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (chatContentRef.current) {
        chatContentRef.current.setScrollTop(
          persistent[`chatLocation-${question_id}`]?.location || 0
        );
      }
    }, 100);
  }, [step, question_id]);

  function saveScrollPosition() {
    if (chatContentRef.current) {
      const currentPosition = chatContentRef.current.getScrollTop();
      dispatch(
        setPersistent({
          [`chatLocation-${question_id}`]: {
            location: currentPosition || 0
          }
        })
      );
    }
  }

  function PendingBubble() {
    return (
      <NewChatBubble
        variation={CONVERSATION_TYPES.INCOMING}
        content={
          <BouncingDots size="large" color={theme.palette.primary.dark} />
        }></NewChatBubble>
    );
  }

  return (
    <Box
      ref={chatBoxRef}
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <ChatActivityRecorder
        course_id={course_id}
        task_id={task_id}
        text_id={text_id}
        question_id={question_id}
        session_id={session_id}
      />
      <Grow
        in={true}
        style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Paper elevation={0} className={classes.chatBox}>
          <ScrollBox
            ref={chatContentRef}
            className={classes.main}
            onMouseLeave={saveScrollPosition}>
            {conversation.map((item, index) => {
              const shouldAnimate =
                index === conversation.length - 1 &&
                item.type === CONVERSATION_TYPES.INCOMING &&
                item.seenByUser === false;
              return (
                <NewChatBubble
                  key={index}
                  variation={item.type}
                  content={item.content}
                  animate={shouldAnimate}
                  scroll={scrollChatToBottom}
                />
              );
            })}

            {isLoading && <PendingBubble />}
          </ScrollBox>
          <Box className={classes.footer}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                borderRadius: "0 10px 10px 10px",
                border: "2px solid",
                borderColor: "#90CAF9",
                padding: theme.spacing(1),
                alignItems: "flex-end"
              }}>
              <TextField
                multiline
                maxRows={10}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                  if (!activeChat) {
                    setActiveChat(true);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    dispatch(outgoingMessage({ content: message }));
                    setMessage("");
                  }
                }}
                className={classes.input}
                placeholder="Start typing..."></TextField>
              <IconButton
                disabled={isLoading || !message}
                size="small"
                onClick={() => {
                  dispatch(outgoingMessage({ content: message }));
                  setMessage("");
                }}
                sx={{
                  backgroundColor: theme.palette.blue.secondary,
                  "&:hover": {
                    backgroundColor: "primary.dark"
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#9E9E9E",
                    opacity: 0.38
                  }
                }}>
                <SendIcon sx={{ color: theme.palette.grey.paper }} />
              </IconButton>
            </Box>
          </Box>
        </Paper>
      </Grow>
    </Box>
  );
}

NewChat.propTypes = {};
export default NewChat;
