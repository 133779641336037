import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import MainActionBar from "./MainContent/ActionBar/MainActionBar";
import { selectSubmission } from "../../../redux/tasksSlice";
import { useQuery } from "../../../hooks";
import {
  selectCurrentInteraction,
  setSelectedInteractionId,
  selectedQuestionHighlights
} from "../../../redux/interactionsSlice";
import { VIEWS } from "./consts";
import BookView from "../../reader/BookView";
import { HIGHLIGHTS } from "../../../consts";
import CardsView from "../../SharedComponents/cards/CardsView";
import ReviewEvidenceToolTip from "../../SharedComponents/ReviewEvidenceToolTip";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      height: "100%",
      backgroundColor: theme.palette.grey.paper,
      display: "flex",
      flexDirection: "column",
      borderRadius: "10px",
      overflow: "hidden" // Important for sticky positioning
    },
    readerView: {
      position: "relative",
      flex: 1,
      height: "100%",
      display: "flex",
      flexDirection: "column", // Changed to column for proper action bar placement
      justifyContent: "flex-start", // Align to top
      overflow: "auto" // Enable scrolling
    },
    innerWrapper: {
      position: "relative",
      display: "flex",
      flexFlow: "column",
      width: "100%",
      height: "100%",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    content: {
      width: "100%",
      flex: 1, // Take remaining space
      alignContent: "flex-end",
      display: "flex",
      textAlign: "center",
      overflow: "auto" // Enable scrolling of content
    },
    cardsList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%"
    }
  };
});

const ContentPanel = ({
  activeMode,
  handleModeChange,
  combinedViewMode,
  setCombinedViewMode
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { submission_id } = useQuery();
  const dispatch = useDispatch();
  const [view, setView] = useState(VIEWS.READER);
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const [contentWidth, setContentWidth] = useState(1000); // Default width

  const questionHighlights = useSelector(selectedQuestionHighlights);
  const isSubmitted = submission.status === "Submitted";
  const readerView = view === VIEWS.READER;
  const containerRef = useRef(null);
  const questions = useSelector((state) => state.interactions.questions);
  const selectedQuestion = useSelector(selectCurrentInteraction);

  // Use ResizeObserver to track the container width
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width } = entries[0].contentRect;
        setContentWidth(width);
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!selectedQuestion?.id)
      dispatch(setSelectedInteractionId(questions[0].id));
  }, [selectedQuestion]);

  return (
    <Box className={classes.container} ref={containerRef}>
      <Box className={classes.readerView}>
        <MainActionBar
          setView={setView}
          view={view}
          contentWidth={contentWidth}
          activeMode={activeMode}
          handleModeChange={handleModeChange}
          combinedViewMode={combinedViewMode}
          setCombinedViewMode={setCombinedViewMode}
        />

        <Box className={classes.content}>
          {readerView ? (
            <BookView
              readOnly={submission.status === "Submitted"}
              minimalBar={false}
              disableInteractions={isSubmitted}
              isVisible={true}
            />
          ) : (
            <Box className={classes.cardsList}>
              {questionHighlights.length ? (
                <Box className={classes.stageHeaderContent}>
                  <Typography variant="h6" sx={{ lineHeight: "40px" }}>
                    {intl.formatMessage({
                      id: "task.stage.ReviewSupportingEvidence",
                      defaultMessage: "Review supporting evidence"
                    })}
                  </Typography>
                  {!isSubmitted && <ReviewEvidenceToolTip />}
                </Box>
              ) : (
                <></>
              )}
              <CardsView
                color={"secondary"}
                context={HIGHLIGHTS.CONTEXT.ANSWERS}
                cards={questionHighlights}
                disable={submission.status === "Submitted"}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContentPanel;
