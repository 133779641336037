import React from "react";
import { Box, Switch, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReaderActions from "./ReaderActions";
import PdfActionBar from "./PdfActionBar";
import TabNavigation from "../../LeftPanel/TabNavigation";
import { PANEL_MODES } from "../../consts";

const useStyles = makeStyles((theme) => ({
  actionBarWrapper: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    width: "100%", // Full width of parent
    backgroundColor: theme.palette.background.paper
  },
  actionBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "56px",
    backgroundColor: theme.palette.grey.paper,
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
    width: "auto" // Full width of parent
  },
  actionGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginInlineEnd: theme.spacing(2)
  },
  combinedViewSwitch: {
    marginLeft: theme.spacing(2),
    fontSize: "14px",
    "& .MuiFormControlLabel-label": {
      fontSize: "14px"
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        color: theme.palette.primary.main,
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.blue.tertiary,
          opacity: 0.7
        }
      }
    }
  }
}));

const MainActionBar = ({
  view,
  setView,
  contentWidth,
  activeMode,
  handleModeChange,
  combinedViewMode,
  setCombinedViewMode
}) => {
  const classes = useStyles();
  const highlightView = activeMode === PANEL_MODES.HIGHLIGHTS;

  const handleCombinedViewToggle = () => {
    setCombinedViewMode(!combinedViewMode);
  };

  return (
    <Box className={classes.actionBarWrapper}>
      <Box className={classes.actionBar}>
        <TabNavigation
          activeMode={activeMode}
          onModeChange={handleModeChange}
        />
        <Box className={classes.actionGroup}>
          {highlightView ? (
            <>
              <PdfActionBar contentWidth={contentWidth} />
              <ReaderActions
                setView={setView}
                view={view}
                contentWidth={contentWidth}
              />
            </>
          ) : (
            <>
              <ReaderActions
                setView={setView}
                view={view}
                contentWidth={contentWidth}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={combinedViewMode}
                    onChange={handleCombinedViewToggle}
                    className={classes.combinedViewSwitch}
                  />
                }
                label="Combined view"
                labelPlacement="start"
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MainActionBar;
