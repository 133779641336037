// Dependencies
import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { interval, of, fromEvent } from "rxjs";
import { map, take, concatMap, tap, mergeMap, takeUntil } from "rxjs/operators";
import { Typography, Grow, Box } from "@mui/material";
import { visuallyHidden } from "@mui/utils"; // MUI equivalent of sr-only
import makeStyles from "@mui/styles/makeStyles";

// Styles with enhanced focus indicators and ensuring sufficient color contrast
const useStyles = makeStyles((theme) => {
  return {
    bubble: {
      display: "block",
      width: "fit-content",
      marginBottom: theme.spacing(2),
      borderRadius: "8px",
      position: "relative", // For focus outline
      "&:focus": {
        outline: `3px solid ${theme.palette.primary.main}`,
        outlineOffset: "2px"
      },
      "&:focus:not(:focus-visible)": {
        outline: "none"
      }
    },
    incoming: {
      marginInlineEnd: theme.spacing(3),
      background: theme.palette.grey.paper,
      color: theme.palette.blue.primary,
      "@media (prefers-color-scheme: dark)": {
        background: theme.palette.primary.dark
      }
    },
    outgoing: {
      marginInlineStart: theme.spacing(3),
      padding: theme.spacing(2),
      background: theme.palette.blue.babyBlue,
      alignSelf: "end",
      "@media (prefers-color-scheme: dark)": {
        background: theme.palette.action.selected
      }
    }
  };
});

function NewChatBubble({
  content,
  variation,
  scroll,
  animate = false,
  timestamp = new Date().toISOString()
}) {
  const classes = useStyles();
  const ref = useRef();
  const [contentForDisplay, setContentForDisplay] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const scrollContainer = useCallback((stream$) => {
    return stream$.pipe(tap(scroll()));
  }, []);

  const typeEffect = useCallback(({ content, speed }) => {
    return interval(speed).pipe(
      map((x) => content.substr(0, x + 1)),
      take(content.length)
    );
  }, []);

  useEffect(() => {
    if (animate) {
      setIsTyping(true);
      const words$ = of(content);
      const scroll$ = fromEvent(ref.current.parentElement, "wheel");

      const typedWords$ = words$.pipe(
        concatMap((words) => typeEffect({ content: words, speed: 70 }))
      );

      const scrollContainer$ = typedWords$.pipe(
        map((words) => of(words)),
        mergeMap((stream$) => scrollContainer(stream$)),
        takeUntil(scroll$)
      );

      const subscription = typedWords$.subscribe({
        next: (content) => setContentForDisplay(content),
        complete: () => setIsTyping(false)
      });

      scrollContainer$.subscribe();

      return () => subscription.unsubscribe();
    } else {
      setContentForDisplay(content);
      setIsTyping(false);
    }
  }, [animate, content, scrollContainer, typeEffect]);

  const formattedTime = new Date(timestamp).toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric"
  });

  const sender = variation === "INCOMING" ? "Assistant" : "You";

  return (
    <Grow in={true} timeout={500}>
      <Box
        ref={ref}
        className={clsx(classes.bubble, classes[variation?.toLowerCase()])}
        role="log"
        aria-live={animate ? "polite" : "off"}
        aria-atomic="true"
        aria-busy={isTyping}
        tabIndex={0}>
        {/* Screen reader only timestamp and sender info */}
        <Box sx={visuallyHidden}>
          <Typography variant="body2">
            {`Message from ${sender} at ${formattedTime}`}
          </Typography>
        </Box>

        {/* Visible content */}
        <Typography
          sx={{ fontSize: "16px" }}
          variant="body2"
          component="div"
          aria-hidden={isTyping}>
          {contentForDisplay}
        </Typography>

        {/* Status for screen readers during animation */}
        {isTyping && (
          <Box sx={visuallyHidden}>
            <Typography variant="body2" role="status">
              Message is being typed...
            </Typography>
          </Box>
        )}
      </Box>
    </Grow>
  );
}

NewChatBubble.propTypes = {
  content: PropTypes.node.isRequired,
  variation: PropTypes.oneOf(["INCOMING", "OUTGOING"]).isRequired,
  animate: PropTypes.bool,
  scroll: PropTypes.func,
  timestamp: PropTypes.string
};

export default NewChatBubble;
