// Dependencies
import React, { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { Draggable } from "react-beautiful-dnd";
import { tasksAPI } from "../../../api";

// Redux Dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { setPersistent } from "../../../redux/userSlice.js";

//Components
import TaskListItemDetails from "./TaskListItemDetails";
import ToggleTriangle from "../../SharedComponents/ToggleTriangle";
import DeadlineDatePicker from "./DeadlineDatePicker";

// Material UI
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Button
} from "@mui/material";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";
import { setSelectedTextId } from "../../../redux/textsSlice";
import { selectDarkMode } from "../../../redux/firestoreSelectors";
import { toZonedTime } from "date-fns-tz";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  task: {
    minHeight: 72, // TODO: not great, find a better way to force the height
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px"
  },
  taskHeader: {
    padding: "8px 16px"
  },
  taskSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  taskName: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textAlign: "start",
    cursor: "pointer"
  },
  taskDates: {
    display: "flex",
    fontWeight: "100",
    gap: "30px",
    justifyContent: "center",
    minWidth: "400px"
  },
  taskDeadline: {
    color: theme.palette.primary.dark
  },
  actionContainer: {
    flexShrink: "0"
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  taskStatus: {
    color: theme.palette.text.secondary,
    margin: "0 48px"
  },
  disableLink: {
    pointerEvents: "none",
    color: "#666666"
  }
}));

export default function TasksListItem({
  taskIndex,
  task,
  submissions,
  graders,
  course,
  singleItemView,
  onDragEnd,
  tasksLength
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => selectDarkMode(state));
  const persistent = useSelector((state) => state.user.persistent);
  const expandedAssignments =
    persistent["assignments"]?.expandedAssignments || [];

  // Ephemeral State
  const isOpen = singleItemView || expandedAssignments.includes(task.id);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);

  const [tzDueDate, setTzDueDate] = useState(
    toZonedTime(
      task.original_due_date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    )
  );
  const isDraft = task.status === "Draft";
  const isPending = task.status === "PENDING";
  const isDisabled = isDraft || isPending;
  const taskName = task.name;
  //Behavior

  const handleClick = (e, taskId) => {
    dispatch(
      setPersistent({
        assignments: {
          expandedAssignments: isOpen
            ? expandedAssignments.filter((id) => id !== taskId)
            : [...expandedAssignments, taskId]
        }
      })
    );
    e.stopPropagation();
    e.preventDefault();
  };

  const handleKeyboardDrag = (event, taskIndex) => {
    if (event.ctrlKey && event.shiftKey) {
      if (event.key === "ArrowUp" && taskIndex > 0) {
        onDragEnd({
          source: { index: taskIndex },
          destination: { index: taskIndex - 1 }
        });
        event.preventDefault();
      } else if (event.key === "ArrowDown" && taskIndex < tasksLength - 1) {
        onDragEnd({
          source: { index: taskIndex },
          destination: { index: taskIndex + 1 }
        });
        event.preventDefault();
      }
    }
  };

  function getLinkTarget(task) {
    if (isDraft) {
      return `/tasks/new?course_id=${task.course_id}&task_id=${task.id}`;
    } else if (submissions?.length) {
      return `/tasks?course_id=${task.course_id}&task_id=${task.id}`;
    } else return `#`;
  }

  useEffect(() => {
    setTzDueDate(
      toZonedTime(
        task.original_due_date,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    );
  }, [task.original_due_date]);

  // Display the task's name and the status if aplicable
  function renderTaskName(task) {
    return (
      <>
        <Button
          component={RouterLink}
          to={getLinkTarget(task)}
          className={clsx(classes.taskName, isDisabled && classes.disableLink)}
          aria-label={`${taskName || "Untitled task"} ${isDraft ? "(Draft)" : ""}`}>
          <Typography component="h2" variant="body2">
            {taskName}
          </Typography>
        </Button>
        {/* Status information outside the interactive element */}
        {isDraft && (
          <Typography
            className={classes.taskStatus}
            component="span"
            variant="body2"
            role="status">
            <FormattedMessage id="Draft" defaultMessage="Draft" />
            {/* ... */}
          </Typography>
        )}
      </>
    );
  }

  function renderTaskActions() {
    if (isPending) return null;
    else if (isDraft) {
      return (
        <Box
          className={classes.actionContainer}
          role="toolbar"
          aria-label="Task actions">
          <TooltipWithIntl
            intlStringId="draft.deleteDraft"
            defaultMessage="Delete draft"
            placement="bottom">
            <IconButton
              onClick={() => tasksAPI.deleteTask(task, false)}
              aria-label={`Delete draft: ${taskName}`}
              size="large">
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
          <TooltipWithIntl
            intlStringId="draft.editDraft"
            defaultMessage="Edit draft"
            placement="bottom">
            <IconButton
              onClick={(e) => {
                navigate(
                  `/tasks/new?course_id=${task.course_id}&task_id=${task.id}`,
                  { task: task }
                );
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label={`Edit draft: ${taskName}`}
              size="large">
              <EditOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
        </Box>
      );
    } else {
      return (
        <Box
          className={classes.actionContainer}
          role="toolbar"
          aria-label="Task actions">
          <TooltipWithIntl
            intlStringId="task.taskmanager.viewTaskForm"
            defaultMessage="View assignment"
            placement="bottom">
            <IconButton
              onClick={(e) => {
                dispatch(setSelectedTextId(task.text_id));
                navigate(`/tasks/edit?task_id=${task.id}`, {
                  task: { ...task, submissions: submissions },
                  course: course
                });
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label={`View assignment: ${taskName}`}
              size="large">
              <InsertDriveFileIcon />
            </IconButton>
          </TooltipWithIntl>
          <TooltipWithIntl
            intlStringId="task.taskmanager.taskStats"
            defaultMessage="Assignment analytics"
            placement="bottom">
            <IconButton
              onClick={(e) => {
                navigate(`/reports/stats?task_id=${task.id}`);
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label={`View analytics for ${taskName}`}
              size="large">
              <InsertChartIcon />
            </IconButton>
          </TooltipWithIntl>
        </Box>
      );
    }
  }

  const getItemStyle = (isDragging, draggableStyle, darkMode) => {
    return {
      // styles we need to apply on draggables
      ...draggableStyle,

      ...(isDragging && {
        display: "table",
        background: darkMode ? "rgb(5,15,15)" : "rgb(235,235,235)"
      })
    };
  };

  //set breadcrumbs for single task view by teacher
  useEffect(() => {
    let parts = [];
    parts.push({
      url: "/tasks",
      resetCourse: true,
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      })
    });
    task &&
      course.name &&
      parts.push({
        url: `/tasks?course_id=${task.course_id}`,
        text: course.name,
        course: course
      });
    singleItemView &&
      task &&
      parts.push({
        url: `/tasks?course_id=${task.course_id}&task_id=${task.id}`,
        text: task.name
      });
    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course?.name, task.id, task.course_id]);

  // Render
  return (
    <>
      {singleItemView ? (
        <>
          <Box
            className={clsx(classes.task, classes.taskHeader)}
            role="region"
            aria-label={`Task details for ${taskName}`}>
            <Box className={classes.taskSummary}>
              {renderTaskName(task)}
              {isOpen && !isDisabled && (
                <Box
                  className={classes.taskDates}
                  role="group"
                  aria-label="Task deadlines">
                  {task.original_due_date && (
                    <>
                      <DeadlineDatePicker
                        type="date"
                        isOpen={datePickerOpen}
                        setIsOpen={setDatePickerOpen}
                        deadline={task.original_due_date}
                        label={intl.formatMessage({
                          id: "tasks.editDueDate",
                          defaultMessage: "Edit due date"
                        })}
                        onChange={(newDueDate) =>
                          tasksAPI.updateDueDateForAllStudents(task, newDueDate)
                        }>
                        <Typography
                          component="span"
                          variant="body1"
                          onClick={() => setDatePickerOpen(true)}
                          onKeyPress={(e) =>
                            e.key === "Enter" && setDatePickerOpen(true)
                          }
                          role="button"
                          tabIndex={0}
                          className={classes.taskDeadline}
                          aria-label={`Edit due date: ${format(tzDueDate, "MMMM d, yyyy")}`}>
                          {format(tzDueDate, "MM/dd/yyyy")}
                        </Typography>
                      </DeadlineDatePicker>

                      <DeadlineDatePicker
                        type="time"
                        isOpen={timePickerOpen}
                        setIsOpen={setTimePickerOpen}
                        deadline={task.original_due_date}
                        label={intl.formatMessage({
                          id: "tasks.editDueTime",
                          defaultMessage: "Edit due time"
                        })}
                        onChange={(newDueDate) =>
                          tasksAPI.updateDueDateForAllStudents(task, newDueDate)
                        }>
                        <Typography
                          component="span"
                          variant="body1"
                          onClick={() => setTimePickerOpen(true)}
                          onKeyPress={(e) =>
                            e.key === "Enter" && setTimePickerOpen(true)
                          }
                          role="button"
                          tabIndex={0}
                          className={classes.taskDeadline}
                          aria-label={`Edit due time: ${format(tzDueDate, "h:mm a")}`}>
                          {format(tzDueDate, "hh:mm a")}
                        </Typography>
                      </DeadlineDatePicker>
                    </>
                  )}
                </Box>
              )}
              {renderTaskActions()}
            </Box>
          </Box>
          <TaskListItemDetails
            taskIndex={taskIndex}
            course={course}
            task={task}
            submissions={submissions}
            graders={graders}
          />
        </>
      ) : (
        <Draggable draggableId={"task_" + task.id} index={taskIndex}>
          {(provided, snapshot) => (
            <Box
              onKeyDown={(event) => handleKeyboardDrag(event, taskIndex)}
              role="list"
              aria-label={`Task item: ${taskName}`}>
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                  darkMode
                )}
                className={classes.task}
                disableGutters={true}
                data-testid="task-row"
                role="listitem"
                aria-label={`Draggable task item: ${taskName}`}>
                <ListItemIcon
                  onClick={() => handleClick(event, task.id)}
                  component="div" // Ensure this is not an interactive element
                >
                  {!isDisabled && <ToggleTriangle isOpen={isOpen} />}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  className={classes.taskSummary}>
                  {renderTaskName(task)}
                  {isOpen && !isDisabled && (
                    <Box className={classes.taskDates}>
                      {task.original_due_date && (
                        <>
                          <DeadlineDatePicker
                            type={"date"}
                            isOpen={datePickerOpen}
                            setIsOpen={setDatePickerOpen}
                            deadline={task.original_due_date}
                            label="Edit due date"
                            onChange={(newDueDate) =>
                              tasksAPI.updateDueDateForAllStudents(
                                task,
                                newDueDate
                              )
                            }>
                            <TooltipWithIntl
                              intlStringId="tasks.editDueDate"
                              defaultMessage="Edit due date"
                              placement="bottom">
                              <Link
                                component="button"
                                onClick={() => {
                                  setDatePickerOpen(true);
                                }}
                                className={classes.taskDeadline}
                                underline="hover">
                                <Typography component="span" variant="body1">
                                  {` ${format(tzDueDate, "MM/dd/yyyy")}`}
                                </Typography>
                              </Link>
                            </TooltipWithIntl>
                          </DeadlineDatePicker>
                          <DeadlineDatePicker
                            type={"time"}
                            isOpen={timePickerOpen}
                            setIsOpen={setTimePickerOpen}
                            deadline={task.original_due_date}
                            label="Edit due time"
                            onChange={(newDueDate) =>
                              tasksAPI.updateDueDateForAllStudents(
                                task,
                                newDueDate
                              )
                            }>
                            <TooltipWithIntl
                              intlStringId="tasks.editDueTime"
                              defaultMessage="Edit due time"
                              placement="bottom">
                              <Link
                                component="button"
                                onClick={() => {
                                  setTimePickerOpen(true);
                                }}
                                className={classes.taskDeadline}
                                underline="hover">
                                <Typography component="span" variant="body1">
                                  {` ${format(tzDueDate, "hh:mm a")}`}
                                </Typography>
                              </Link>
                            </TooltipWithIntl>
                          </DeadlineDatePicker>
                        </>
                      )}
                    </Box>
                  )}
                  {renderTaskActions()}
                </ListItemText>
              </ListItem>
              <Collapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
                id={`task-details-${task.id}`}
                role="region"
                aria-label={`Additional details for ${taskName}`}>
                <TaskListItemDetails
                  taskIndex={taskIndex}
                  course={course}
                  task={task}
                  submissions={submissions}
                  graders={graders}
                />
              </Collapse>
            </Box>
          )}
        </Draggable>
      )}
    </>
  );
}
