import { doc, getDoc } from "firebase/firestore";

/**
 * Fetches user layout settings from Firestore
 *
 * @param {object} firestore - Firestore instance
 * @param {string} userId - User ID
 * @param {string} submissionId - Submission ID
 * @returns {Promise<object>} - Layout settings or null
 */
export const fetchUserLayoutSettings = async (
  firestore,
  userId,
  submissionId
) => {
  if (!userId) return null;

  try {
    const userDocRef = doc(firestore, `users/${userId}`);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();

      if (
        userData.layoutSettings &&
        userData.layoutSettings[submissionId || "DEFAULT"]
      ) {
        return userData.layoutSettings[submissionId || "DEFAULT"];
      }
    }
    return null;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

/**
 * Get minimum width constraints based on device type
 *
 * @param {object} device - Device detection object
 * @returns {number} - Minimum panel width
 */
export const getMinPanelWidth = (device) => {
  if (device.isIPad || device.isTablet) {
    return 260; // iPad and tablet minimum
  } else if (device.isMobile) {
    return 220; // Mobile minimum
  } else {
    return 283; // Desktop minimum
  }
};

/**
 * Determine if desktop layout should be used based on device and breakpoints
 *
 * @param {object} device - Device detection object
 * @param {boolean} isSmUp - SM breakpoint media query result
 * @param {boolean} isMdUp - MD breakpoint media query result
 * @returns {boolean} - Whether to use desktop layout
 */
export const determineIsDesktopLayout = (device, isSmUp, isMdUp) => {
  if (device.isIPad || device.isTablet) {
    // For tablets, use sm breakpoint instead of md
    return isSmUp;
  }
  // For mobile devices, always use mobile layout
  if (device.isMobile) {
    return false;
  }
  // For desktop devices, use md breakpoint
  return isMdUp;
};
