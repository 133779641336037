// Dependencies
import React, { useState } from "react";
import { useIntl } from "react-intl"; // We don't need both, use only the hook
import { useNavigate } from "react-router";
import { isEmpty } from "lodash-es";
import { useQuery } from "../../hooks";

// Redux
import { useSelector } from "react-redux";
import { selectSubmission } from "../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../redux/coursesSlice";

// Components
import SubmitTaskModal from "../Tasks/TaskAnswer/SubmitTaskModal";

import { Box, Button } from "@mui/material";
import useCreatePortal from "../../hooks/useCreatePortal";

export default function TasksButton() {
  //Hooks
  const navigate = useNavigate();
  const intl = useIntl();
  const { submission_id } = useQuery();

  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );

  //Redux State
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );

  // Ephemeral State
  const [dialogOpen, setDialogOpen] = useState(false);

  //Deravied state
  const isTaskActive =
    submission.status === "Pending" || submission.status === "Active";
  const showSubmit = isTaskActive;
  const showDone = !isTaskActive;

  return (
    <>
      <ActionButtonPortal>
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          {showSubmit && (
            <Button
              sx={{ marginTop: "0" }}
              onClick={() => setDialogOpen(true)}
              data-testid="submit-task-button"
              size="small">
              {intl.formatMessage({
                id: "task.submission.submit",
                defaultMessage: "Submit"
              })}
            </Button>
          )}
          {showDone && (
            <Button
              onClick={() => {
                navigate(`/tasks?course_id=${course.id}`);
              }}
              data-testid="submit-task-button"
              size="small">
              {intl.formatMessage({
                id: "gr.confirm.btn",
                defaultMessage: "Done"
              })}
            </Button>
          )}
        </Box>
      </ActionButtonPortal>
      {!isEmpty(submission) && (
        <SubmitTaskModal
          taskId={submission.task_id}
          modalVisible={dialogOpen}
          setModalVisible={setDialogOpen}
        />
      )}
    </>
  );
}

TasksButton.propTypes = {};
