import React from "react";
import { Box, IconButton } from "@mui/material";
import { Assistant } from "@mui/icons-material";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { TooltipWithIntl } from "../../../SharedComponents/index.js";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  actionGroup: (props) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: props.isHorizontal ? "row" : "column"
  }),
  viewButton: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    display: "flex",
    height: "36px",
    width: "36px",
    padding: "8px",
    alignItems: "center",
    backgroundColor: theme.palette.grey.paper,
    "&:hover": {
      backgroundColor: theme.palette.action.hover || "rgba(0, 0, 0, 0.04)"
    }
  },
  coachButton: (props) => ({
    borderRadius: props.isHorizontal ? "4px 0 0 3px" : "4px 4px 0 0",
    borderRight: props.isHorizontal ? "0" : "0",
    borderLeft: props.isHorizontal
      ? `1px solid ${theme.palette.grey.border}`
      : "0",
    borderBottom: props.isHorizontal
      ? `1px solid ${theme.palette.grey.border}`
      : "0",
    borderTop: props.isHorizontal
      ? `1px solid ${theme.palette.grey.border}`
      : "0",
    boxShadow: props.isHorizontal ? "0" : "0 2px 4px rgba(0,0,0,0.1)"
  }),
  commentsButton: (props) => ({
    borderRadius: props.isHorizontal ? " 0 4px 4px 0 " : "0 0 4px 4px",
    borderLeft: "0",
    borderRight: props.isHorizontal
      ? `1px solid ${theme.palette.grey.border}`
      : "0",
    borderTop: props.isHorizontal
      ? `1px solid ${theme.palette.grey.border}`
      : "0",
    borderBottom: props.isHorizontal
      ? `1px solid ${theme.palette.grey.border}`
      : "0",
    boxShadow: props.isHorizontal ? "0" : "0 2px 4px rgba(0,0,0,0.1)"
  }),
  selectedView: {
    backgroundColor: theme.palette.grey.selected || "#f5f5f5"
  }
}));

const RightSideButtonGroup = ({
  view,
  onViewClick,
  isHorizontal,
  toolTipPlacement = "top"
}) => {
  const classes = useStyles({ isHorizontal });

  return (
    <Box className={classes.actionGroup}>
      <TooltipWithIntl
        defaultMessage={"Academic coach"}
        intlStringId={"Academic coach"}
        placement={toolTipPlacement}>
        <IconButton
          aria-label="Assistant"
          size="large"
          className={clsx(
            classes.viewButton,
            classes.coachButton,
            view === "chat" && classes.selectedView
          )}
          onClick={() => {
            onViewClick("chat");
          }}>
          <Assistant fontSize="small" />
        </IconButton>
      </TooltipWithIntl>
      <TooltipWithIntl
        defaultMessage={"Comments"}
        intlStringId={"Comments"}
        placement={toolTipPlacement}>
        <IconButton
          aria-label="Comments"
          size="large"
          className={clsx(
            classes.viewButton,
            classes.commentsButton,
            view === "comment" && classes.selectedView
          )}
          onClick={() => {
            onViewClick("comment");
          }}>
          <CommentOutlinedIcon fontSize="small" />
        </IconButton>
      </TooltipWithIntl>
    </Box>
  );
};

export default RightSideButtonGroup;
