import React from "react";
import PropTypes from "prop-types";
import { Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import QuestionCard from "./QuestionCard";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

const useStyles = makeStyles((theme) => ({
  questionList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "auto"
  },
  instructorQuestions: {
    marginBottom: theme.spacing(2)
  },
  studentQuestions: {
    marginTop: theme.spacing(2)
  },
  divider: {
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(2, 0)
  }
}));

const QuestionList = ({
  instructorQuestions = [],
  studentQuestions = [],
  highlights = [],
  answers = [],
  selectedQuestionId,
  onQuestionSelect,
  onAnswerClick,
  onSortQuestions
}) => {
  const classes = useStyles();

  // Check if a question has an answer
  const hasAnswer = (questionId) => {
    return answers.some((answer) => answer.interaction_id === questionId);
  };
  console.log(instructorQuestions);
  console.log(selectedQuestionId);
  return (
    <Box className={classes.questionList}>
      {/* Instructor Questions */}
      {instructorQuestions.length > 0 && (
        <Box className={classes.instructorQuestions}>
          {instructorQuestions.map((question, index) => (
            <QuestionCard
              key={question.id}
              question={question}
              index={index}
              isSelected={selectedQuestionId === question.id}
              highlights={highlights}
              hasAnswer={hasAnswer(question.id)}
              onClick={onQuestionSelect}
              onAnswerClick={onAnswerClick}
            />
          ))}
        </Box>
      )}

      {/* Divider if both instructor and student questions exist */}
      {instructorQuestions.length > 0 && studentQuestions.length > 0 && (
        <Divider className={classes.divider} />
      )}

      {/* Student Questions - Sortable */}
      {studentQuestions.length > 0 && (
        <SortableContext
          items={studentQuestions.map((q) => `sort-student-questions-${q.id}`)}
          strategy={verticalListSortingStrategy}>
          <Box className={classes.studentQuestions}>
            {studentQuestions.map((question, index) => (
              <QuestionCard
                key={question.id}
                question={question}
                index={index + instructorQuestions.length}
                isSelected={selectedQuestionId === question.id}
                highlights={highlights}
                hasAnswer={hasAnswer(question.id)}
                onClick={onQuestionSelect}
                onAnswerClick={onAnswerClick}
                // Additional props for sortable functionality
                onSortQuestions={onSortQuestions}
              />
            ))}
          </Box>
        </SortableContext>
      )}
    </Box>
  );
};

QuestionList.propTypes = {
  instructorQuestions: PropTypes.array,
  studentQuestions: PropTypes.array,
  highlights: PropTypes.array,
  answers: PropTypes.array,
  selectedQuestionId: PropTypes.string,
  onQuestionSelect: PropTypes.func.isRequired,
  onAnswerClick: PropTypes.func,
  onSortQuestions: PropTypes.func
};

export default QuestionList;
