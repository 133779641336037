import React, { useEffect, useState } from "react";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SplitscreenOutlinedIcon from "@mui/icons-material/SplitscreenOutlined";
import TooltipWithIntl from "../../../../SharedComponents/tooltip/TooltipWithIntl";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Typography, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { useGetTheme } from "../../../../../hooks";
import { VIEWS } from "../../consts";
import { useDispatch, useSelector } from "react-redux";
import { showInText } from "../../../../../utils/showInTextHelper";
import { useRendition } from "../../../../../RenditionContext";
import { appActions } from "../../../../../consts";
import { setSelectedTempHighlight } from "../../../../../redux/interactionsSlice";
import PdfPageNavigation from "./PdfPageNavigation";

const useStyles = makeStyles((theme) => ({
  actionBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 0 0 16px",
    height: "48px",
    backgroundColor: theme.palette.grey.paper,
    zIndex: 1000
  },

  actionGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  actionGroupCompact: {
    marginInlineEnd: "4px",
    "&:last-child": {
      marginBottom: 0
    }
  },
  readerActionButton: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: "4px",
    display: "flex",
    height: "36px",
    width: "36px",
    padding: "8px",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.action.hover || "rgba(0, 0, 0, 0.04)"
    }
  },
  leftButton: {
    borderRadius: "4px 0 0 4px",
    borderRight: "0"
  },
  rightButton: {
    borderRadius: "0 4px 4px 0"
  },
  selectedView: {
    backgroundColor: theme.palette.grey.selected
  },
  isCompatReaderActionButton: {
    height: "30px",
    width: "30px"
  },
  disabledButton: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  menuButton: {
    marginLeft: theme.spacing(1),
    cursor: "pointer"
  },
  menuItem: {
    display: "flex",
    alignItems: "center"
  },
  menuItemText: {
    marginLeft: theme.spacing(1)
  }
}));

const ReaderActions = ({ view, setView, contentWidth = 1000 }) => {
  const classes = useStyles();
  const intl = useIntl();
  const rendition = useRendition();
  const theme = useGetTheme();
  const dispatch = useDispatch();
  const [isCompact, setIsCompact] = useState(false);
  const highlights = useSelector((state) => state.interactions.highlights);
  const user_id = useSelector((state) => state.firebase.auth.uid);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);

  // Menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Check if the content panel is narrow and switch to compact mode
  useEffect(() => {
    setIsCompact(contentWidth < 660);
  }, [contentWidth]);

  // Set initial highlight if available
  useEffect(() => {
    if (highlights && highlights.length > 0) {
      setCurrentHighlightIndex(0);
      const highlight = highlights[0];
      dispatch(setSelectedTempHighlight(highlight));
    } else {
      setCurrentHighlightIndex(-1);
      const highlight = highlights[-1];
      dispatch(setSelectedTempHighlight(highlight));
    }
  }, [highlights]);

  const readerView = view === VIEWS.READER;
  const highlightsView = view === VIEWS.HIGHLIGHTS;

  // Navigate to specific highlight by index
  const navigateToHighlight = (index) => {
    if (
      !highlights ||
      highlights.length === 0 ||
      index < 0 ||
      index >= highlights.length
    ) {
      return;
    }

    const highlight = highlights[index];
    dispatch(setSelectedTempHighlight(highlight));
    setCurrentHighlightIndex(index);
    showInText(appActions.SHOULD_LOG, highlight, user_id, rendition);
  };

  // Navigate to previous highlight
  const navigateToPreviousHighlight = () => {
    if (currentHighlightIndex > 0) {
      navigateToHighlight(currentHighlightIndex - 1);
    } else if (currentHighlightIndex === 0) {
      navigateToHighlight(highlights.length - 1);
    }
    handleMenuClose();
  };

  // Navigate to next highlight
  const navigateToNextHighlight = () => {
    if (currentHighlightIndex < highlights.length - 1) {
      navigateToHighlight(currentHighlightIndex + 1);
    } else if (currentHighlightIndex === highlights.length - 1) {
      navigateToHighlight(0);
    }
    handleMenuClose();
  };

  return (
    <Box className={clsx(classes.actionBar)}>
      <Box
        className={clsx(
          classes.actionGroup,
          isCompact && classes.actionGroupCompact
        )}
        sx={{
          marginInlineEnd: !isCompact ? theme.spacing(2) : 0
        }}>
        <TooltipWithIntl
          defaultMessage={"Show text"}
          intlStringId={"Show text"}
          placement="bottom">
          <IconButton
            aria-label="Show text"
            size="medium"
            label="Text"
            className={clsx(
              classes.readerActionButton,
              classes.leftButton,
              readerView && classes.selectedView,
              isCompact && classes.isCompatReaderActionButton
            )}
            onClick={() => {
              if (readerView) {
                setView(VIEWS.HIGHLIGHTS);
              } else if (highlightsView) {
                setView(VIEWS.READER);
              }
            }}>
            <ArticleOutlinedIcon fontSize="small" />
          </IconButton>
        </TooltipWithIntl>
        <TooltipWithIntl
          defaultMessage={"Show highlights cards"}
          intlStringId={"Show highlights cards"}
          placement="bottom">
          <IconButton
            aria-label="Show highlights cards"
            size="medium"
            label="Highlights cards"
            className={clsx(
              classes.readerActionButton,
              classes.rightButton,
              highlightsView && classes.selectedView,
              isCompact && classes.isCompatReaderActionButton
            )}
            onClick={() => {
              if (highlightsView) {
                setView(VIEWS.READER);
              } else {
                setView(VIEWS.HIGHLIGHTS);
              }
            }}>
            <SplitscreenOutlinedIcon fontSize="small" />
          </IconButton>
        </TooltipWithIntl>
      </Box>

      {/* More options button */}
      <TooltipWithIntl
        defaultMessage={"Highlight navigation"}
        intlStringId={"Highlight navigation"}
        placement="bottom">
        <IconButton
          aria-label="More options"
          size="medium"
          onClick={handleMenuClick}
          className={classes.menuButton}>
          <MoreVertIcon />
        </IconButton>
      </TooltipWithIntl>

      {/* Menu for highlight navigation */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "highlight-navigation-menu"
        }}>
        <MenuItem className={classes.menuItem}>
          <Typography variant="caption" component={"span"}>
            {currentHighlightIndex + 1 || 0} / {highlights.length || 0}{" "}
            {intl.formatMessage({
              id: "task.Highlights",
              defaultMessage: "Highlights"
            })}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={navigateToPreviousHighlight}
          className={classes.menuItem}>
          <KeyboardArrowUpOutlinedIcon fontSize="small" />
          <Typography variant="body2" className={classes.menuItemText}>
            {intl.formatMessage({
              id: "Move highlight up",
              defaultMessage: "Move highlight up"
            })}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={navigateToNextHighlight}
          className={classes.menuItem}>
          <KeyboardArrowDownOutlinedIcon fontSize="small" />
          <Typography variant="body2" className={classes.menuItemText}>
            {intl.formatMessage({
              id: "Move highlight down",
              defaultMessage: "Move highlight down"
            })}
          </Typography>
        </MenuItem>
        <PdfPageNavigation
          contentWidth={contentWidth}
          asMenuItem={true}
          onClose={handleMenuClose}
        />
      </Menu>
    </Box>
  );
};

export default ReaderActions;
