import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import QuestionNavigation from "./QuestionNavigation";
// import QuestionDisplay from "./QuestionDisplay";
// import AnswerEditor from "./AnswerEditor";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  header: {
    minHeight: "146px",
    paddingBlockStart: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.divider}`
  },
  instructions: {
    paddingBlockEnd: theme.spacing(2),
    paddingInline: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    fontSize: "0.875rem"
  },
  content: {
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  wordCount: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem"
  },
  autoSaved: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    display: "flex",
    alignItems: "center"
  },
  autoSavedIcon: {
    fontSize: "1rem",
    marginRight: theme.spacing(0.5)
  }
}));

const AnswerMode = ({ task }) => {
  const classes = useStyles();

  //   const [answer, setAnswer] = useState("");
  //{ questions, currentQuestionIndex, onQuestionChange }
  // Calculate word count
  //   const wordCount = answer.trim() ? answer.trim().split(/\s+/).length : 0;

  //   // Handle answer change
  //   const handleAnswerChange = (newAnswer) => {
  //     setAnswer(newAnswer);
  //   };

  //   // Handle navigation to previous question
  //   const handlePrevQuestion = () => {
  //     if (currentQuestionIndex > 0) {
  //       onQuestionChange(currentQuestionIndex - 1);
  //     }
  //   };

  //   // Handle navigation to next question
  //   const handleNextQuestion = () => {
  //     if (currentQuestionIndex < questions.length - 1) {
  //       onQuestionChange(currentQuestionIndex + 1);
  //     }
  //   };
  return (
    <Box className={classes.container}>
      {/* Instructions */}
      <Box className={classes.header}>
        <Typography variant="h5" sx={{ fontSize: "24px", padding: "12px" }}>
          {task?.name}
        </Typography>
        <Box className={classes.instructions}>
          <Typography variant="body2">
            Answer the question using your supporting evidence
          </Typography>
        </Box>
      </Box>

      {/* Question Navigation */}
      {/* <QuestionNavigation
        currentQuestion={currentQuestionIndex + 1}
        totalQuestions={questions.length}
        onPrev={handlePrevQuestion}
        onNext={handleNextQuestion}
        hasPrev={currentQuestionIndex > 0}
        hasNext={currentQuestionIndex < questions.length - 1}
      /> */}

      {/* Content: Question and Answer area */}
      <Box className={classes.content}>
        {/* Question Display */}
        {/* <QuestionDisplay question={questions[currentQuestionIndex].text} />

        {/* Answer Editor */}
        {/* <AnswerEditor value={answer} onChange={handleAnswerChange} /> */}{" "}
      </Box>

      {/* Footer with word count and auto-save indicator */}
      <Box className={classes.footer}>
        <Typography className={classes.wordCount}>
          {/* Word count: {wordCount} */}
        </Typography>

        <Typography className={classes.autoSaved}>
          <span className={classes.autoSavedIcon}>✓</span>
          Autosaved
        </Typography>
      </Box>
    </Box>
  );
};

export default AnswerMode;
