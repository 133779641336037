import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

const useStyles = makeStyles((theme) => ({
  questionCard: {
    display: "flex",
    flexDirection: "column",
    paddingBlockEnd: theme.spacing(1),
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#EDF2F4",
    cursor: "pointer",
    boxShadow: "0px -1px 0px 0px rgba(255, 255, 255, 0.12) inset",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    }
  },
  selected: {
    background:
      "linear-gradient(0deg, var(--blue-tertiary-45, rgba(144, 202, 249, 0.45)) 0%, var(--blue-tertiary-45, rgba(144, 202, 249, 0.45)) 100%), var(--Greys-Paper, #FFF);    borderLeft: `4px solid ${theme.palette.primary.main}`",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1)
  },
  questionNumber: {
    fontWeight: "bold",
    marginRight: theme.spacing(1)
  },
  questionContent: {
    flexGrow: 1,
    wordBreak: "break-word"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#EDF2F4",
    paddingInline: theme.spacing(1.5)
  },
  highlights: {
    fontSize: "0.875rem"
  },
  answerIcon: {
    padding: theme.spacing(0.5)
  },
  notAnswered: {
    color: theme.palette.text.disabled
  },
  answered: {
    color: theme.palette.primary.main
  },
  teacherQuestion: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`
  },
  studentQuestion: {
    borderLeft: `4px solid ${theme.palette.warning.main}`
  }
}));

const QuestionCard = ({
  question,
  index,
  isSelected,
  highlights = [],
  hasAnswer,
  onClick,
  onAnswerClick
}) => {
  const classes = useStyles();

  const highlightsForQuestion = highlights.filter(
    (highlight) => highlight.interaction_id === question.id
  );

  const isTeacherQuestion = question.user_type === "TEACHER";

  const handleAnswerClick = (e) => {
    e.stopPropagation();
    onAnswerClick && onAnswerClick(question);
  };
  console.log(isSelected);
  return (
    <Box
      className={clsx(
        classes.questionCard,
        isTeacherQuestion ? classes.teacherQuestion : classes.studentQuestion
      )}
      onClick={() => onClick(question)}>
      <Box className={clsx(isSelected && classes.selected, classes.header)}>
        <Typography variant="body1" className={classes.questionNumber}>
          {index + 1}
        </Typography>
        <Typography variant="body1" className={classes.questionContent}>
          {question.content}
        </Typography>
      </Box>

      <Box className={classes.footer}>
        <Typography variant="caption" className={classes.highlights}>
          Highlights: {highlightsForQuestion.length}
        </Typography>
        <IconButton
          size="small"
          className={clsx(
            classes.answerIcon,
            hasAnswer ? classes.answered : classes.notAnswered
          )}
          onClick={handleAnswerClick}
          aria-label={hasAnswer ? "View answer" : "Add answer"}>
          <ChatOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

QuestionCard.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    user_type: PropTypes.string.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  highlights: PropTypes.array,
  hasAnswer: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onAnswerClick: PropTypes.func
};

export default QuestionCard;
