import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Box, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import { useFirestore } from "reactfire";
import { Panel, PanelGroup } from "react-resizable-panels";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import debounce from "lodash/debounce";
import LeftPanel from "./LeftPanel";
import ContentPanel from "./ContentPanel";
import RightPanel from "./RightPanel";
import CustomResizeHandle from "./CustomResizeHandle";
import { PangeaSpinner, ScrollBox } from "../../SharedComponents";
import { useQuery } from "../../../hooks";
import { useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";
import { selectCourse } from "../../../redux/coursesSlice";
import { USER_TYPE } from "../../../consts";
import TaskFeedback from "../TaskFeedback/TaskFeedback";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

// Import only the needed panel utility functions
import {
  determineIsDesktopLayout,
  fetchUserLayoutSettings
} from "./panel-utils";
import { PANEL_MODES } from "./consts";

const useStyles = makeStyles((theme) => {
  return {
    mainContainer: {
      height: "inherit",
      width: "100%",
      backgroundColor: theme.palette.grey.surface,
      padding: `${theme.spacing(2)} 0 0 0`
    },
    panelContainer: {
      height: "100%"
    },
    panelCollapsed: {
      width: "40px !important",
      minWidth: "40px !important",
      maxWidth: "40px !important"
    },
    contentPanel: {
      height: "100%",
      overflow: "auto"
    },
    leftPanelContainer: {
      height: "100%"
    },
    rightPanelContainer: {
      height: "100%"
    },
    panelCollapsedRight: {
      marginRight: theme.spacing(2)
    },
    panelCollapsedLeft: {
      marginLeft: theme.spacing(2)
    }
  };
});

function MainLayout() {
  const classes = useStyles();
  const theme = useTheme();
  const { submission_id, type = null } = useQuery();

  // Use device detection
  const device = useDeviceDetect();

  // Media query hooks
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Determine if we're using the desktop layout
  const isDesktopLayout = determineIsDesktopLayout(device, isSmUp, isMdUp);

  const firestore = useFirestore();
  const userId = useSelector((state) => state.firebase.auth.uid);
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const course = useSelector((state) =>
    selectCourse(state, submission.course_id)
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(false);
  const [rightPanelCollapsed, setRightPanelCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMode, setActiveMode] = useState(PANEL_MODES.HIGHLIGHTS);
  const [combinedViewMode, setCombinedViewMode] = useState(false);
  // References to panel handles for programmatic control
  const leftPanelRef = useRef(null);
  const rightPanelRef = useRef(null);
  const savedSizesRef = useRef({ left: null, right: null });
  const debouncedSavePanelSizesRef = useRef(null);

  // User role
  const isTeacher =
    course.course_role === "Teacher" &&
    type?.toUpperCase() !== USER_TYPE.STUDENT;
  const isCourseAdmin =
    course.course_role === "Admin" && type?.toUpperCase() !== USER_TYPE.STUDENT;

  // Submission status
  const status = submission.status;
  const isGraded = status === "Graded";
  const isSubmitted = status === "Submitted";

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Define pixel constraints based on device type
  const constraints = useMemo(() => {
    // For iPad/tablet: min 283px, max 334px
    // For desktop/laptop: min 334px, max 416px
    return {
      left: {
        minPx: device.isIPad || device.isTablet ? 283 : 334,
        maxPx: device.isIPad || device.isTablet ? 334 : 420,
        defaultPx: device.isIPad || device.isTablet ? 283 : 334
      },
      right: {
        minPx: device.isIPad || device.isTablet ? 283 : 334,
        maxPx: device.isIPad || device.isTablet ? 334 : 420,
        defaultPx: device.isIPad || device.isTablet ? 283 : 334
      }
    };
  }, [device.isIPad, device.isTablet]);

  // Convert pixel values to percentages
  const pixelToPercent = useCallback(
    (pixels) => {
      return (pixels / windowWidth) * 100;
    },
    [windowWidth]
  );

  // Convert percentage to pixels
  const percentToPixel = useCallback(
    (percent) => {
      return (percent / 100) * windowWidth;
    },
    [windowWidth]
  );

  // Calculate panel sizes in percentages
  const panelSizes = useMemo(() => {
    const leftPercent = pixelToPercent(constraints.left.defaultPx);
    const rightPercent = pixelToPercent(constraints.right.defaultPx);
    const centerPercent = Math.max(30, 100 - leftPercent - rightPercent);

    return {
      defaultSizes: [leftPercent, centerPercent, rightPercent],
      minSizes: [
        pixelToPercent(constraints.left.minPx),
        30,
        pixelToPercent(constraints.right.minPx)
      ],
      maxSizes: [
        pixelToPercent(constraints.left.maxPx),
        60,
        pixelToPercent(constraints.right.maxPx)
      ]
    };
  }, [constraints, pixelToPercent, windowWidth]);

  // Create debounced saver for panel sizes
  useEffect(() => {
    debouncedSavePanelSizesRef.current = debounce((data) => {
      if (!userId || !submission_id) return;

      const userDocRef = doc(firestore, `users/${userId}`);
      const layoutPath = `layoutSettings.${submission_id || "DEFAULT"}`;

      const updateData = {
        [layoutPath]: {
          ...data,
          updatedAt: new Date().toISOString()
        }
      };

      updateDoc(userDocRef, updateData)
        .then(() => {
          console.log("Panel sizes saved successfully");
        })
        .catch((error) => {
          if (error.code === "not-found") {
            setDoc(userDocRef, {
              layoutSettings: {
                [submission_id || "DEFAULT"]: {
                  ...data,
                  updatedAt: new Date().toISOString()
                }
              }
            })
              .then(() =>
                console.log("Created new user document with layout settings")
              )
              .catch((err) => console.error("Error creating document:", err));
          } else {
            console.error("Error saving panel sizes:", error);
          }
        });
    }, 1000);

    return () => {
      if (debouncedSavePanelSizesRef.current) {
        debouncedSavePanelSizesRef.current.cancel();
      }
    };
  }, [firestore, userId, submission_id]);

  // Load saved layout
  useEffect(() => {
    if (!userId) return;
    setIsLoading(true);

    const loadUserSettings = async () => {
      try {
        const layoutData = await fetchUserLayoutSettings(
          firestore,
          userId,
          submission_id
        );

        if (layoutData) {
          // If we have saved sizes, update our refs for un-collapsing
          if (layoutData.leftPanelSize !== undefined) {
            savedSizesRef.current.left = layoutData.leftPanelSize;
          }

          if (layoutData.rightPanelSize !== undefined) {
            savedSizesRef.current.right = layoutData.rightPanelSize;
          }

          // Set collapse state
          if (layoutData.leftPanelCollapsed !== undefined) {
            setLeftPanelCollapsed(layoutData.leftPanelCollapsed);
          }

          if (layoutData.rightPanelCollapsed !== undefined) {
            setRightPanelCollapsed(layoutData.rightPanelCollapsed);
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error loading user settings:", error);
        setIsLoading(false);
      }
    };

    loadUserSettings();
  }, [firestore, userId, submission_id]);

  // Panel toggle handlers
  const toggleLeftPanel = () => {
    setLeftPanelCollapsed(!leftPanelCollapsed);

    if (leftPanelRef.current) {
      if (!leftPanelCollapsed) {
        // Save current size before collapsing
        savedSizesRef.current.left = leftPanelRef.current.getSize();
        // Collapse panel
        leftPanelRef.current.collapse();
      } else {
        // Restore panel to saved or default size
        leftPanelRef.current.resize(
          savedSizesRef.current.left || panelSizes.defaultSizes[0]
        );
      }
    }
  };

  const toggleRightPanel = () => {
    setRightPanelCollapsed(!rightPanelCollapsed);

    if (rightPanelRef.current) {
      if (!rightPanelCollapsed) {
        // Save current size before collapsing
        savedSizesRef.current.right = rightPanelRef.current.getSize();
        // Collapse panel
        rightPanelRef.current.collapse();
      } else {
        // Restore panel to saved or default size
        rightPanelRef.current.resize(
          savedSizesRef.current.right || panelSizes.defaultSizes[2]
        );
      }
    }
  };

  // Validate panel resizing to ensure pixel constraints are met
  const validatePanelResize = useCallback(
    (sizes) => {
      // Convert percentages to pixels
      const leftPixels = percentToPixel(sizes[0]);
      const rightPixels = percentToPixel(sizes[2]);

      // Check if sizes are within pixel constraints
      if (
        leftPixels < constraints.left.minPx ||
        leftPixels > constraints.left.maxPx
      ) {
        console.log(
          `Left panel size ${leftPixels}px violates constraints: ${constraints.left.minPx}-${constraints.left.maxPx}px`
        );
        return false;
      }

      if (
        rightPixels < constraints.right.minPx ||
        rightPixels > constraints.right.maxPx
      ) {
        console.log(
          `Right panel size ${rightPixels}px violates constraints: ${constraints.right.minPx}-${constraints.right.maxPx}px`
        );
        return false;
      }

      return true;
    },
    [constraints, percentToPixel]
  );

  // Save panel layout when it changes
  const handlePanelLayout = useCallback(
    (sizes) => {
      // For debugging, log the pixel values
      const leftPx = percentToPixel(sizes[0]);
      const rightPx = percentToPixel(sizes[2]);
      console.log(
        `Panel sizes: Left ${leftPx}px (${sizes[0].toFixed(2)}%), Right ${rightPx}px (${sizes[2].toFixed(2)}%)`
      );

      // Save the sizes to Firestore
      if (debouncedSavePanelSizesRef.current) {
        debouncedSavePanelSizesRef.current({
          leftPanelSize: sizes[0],
          centerPanelSize: sizes[1],
          rightPanelSize: sizes[2],
          leftPanelCollapsed,
          rightPanelCollapsed,
          // Save pixel values for reference
          leftPanelPixels: leftPx,
          rightPanelPixels: rightPx
        });
      }
    },
    [percentToPixel, leftPanelCollapsed, rightPanelCollapsed]
  );

  const handleModeChange = (mode) => {
    setActiveMode(mode);
  };

  if (isLoading) return <PangeaSpinner />;
  else if ((isTeacher || isCourseAdmin) && (isSubmitted || isGraded))
    return <TaskFeedback />;
  else
    return (
      <ScrollBox>
        <Box className={classes.mainContainer}>
          <PanelGroup
            direction="horizontal"
            onLayout={handlePanelLayout}
            onResize={validatePanelResize}
            autoSaveId="main-layout">
            {/* Left Panel */}
            <Panel
              defaultSize={panelSizes.defaultSizes[0]}
              minSize={panelSizes.minSizes[0]}
              maxSize={panelSizes.maxSizes[0]}
              collapsible={false}
              ref={leftPanelRef}
              className={
                (classes.leftPanelContainer,
                leftPanelCollapsed
                  ? clsx(classes.panelCollapsed, classes.panelCollapsedLeft)
                  : "")
              }>
              <LeftPanel
                isCollapsed={leftPanelCollapsed}
                onToggleCollapse={toggleLeftPanel}
                isDesktopLayout={isDesktopLayout}
                activeMode={activeMode}
                handleModeChange={handleModeChange}
                combinedViewMode={combinedViewMode}
                setCombinedViewMode={setCombinedViewMode}
              />
            </Panel>

            {/* Left Resize Handle */}
            <CustomResizeHandle
              isTouchDevice={
                device.isTouchDevice || device.isIPad || device.isTablet
              }
              panelSide="left"
            />

            {/* Content Panel */}
            <Panel defaultSize={panelSizes.defaultSizes[1]} minSize={30}>
              <Box className={classes.contentPanel}>
                <ContentPanel
                  activeMode={activeMode}
                  handleModeChange={handleModeChange}
                  combinedViewMode={combinedViewMode}
                  setCombinedViewMode={setCombinedViewMode}
                />
              </Box>
            </Panel>

            {/* Right Resize Handle */}
            <CustomResizeHandle
              isTouchDevice={
                device.isTouchDevice || device.isIPad || device.isTablet
              }
              panelSide="right"
            />

            {/* Right Panel */}
            <Panel
              defaultSize={panelSizes.defaultSizes[2]}
              minSize={panelSizes.minSizes[2]}
              maxSize={panelSizes.maxSizes[2]}
              collapsible={false}
              ref={rightPanelRef}
              className={
                (classes.rightPanelContainer,
                rightPanelCollapsed
                  ? clsx(classes.panelCollapsed, classes.panelCollapsedRight)
                  : "")
              }>
              <RightPanel
                isCollapsed={rightPanelCollapsed}
                onToggleCollapse={toggleRightPanel}
                isDesktopLayout={isDesktopLayout}
              />
            </Panel>
          </PanelGroup>
        </Box>
      </ScrollBox>
    );
}

export default MainLayout;
