import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";

//API
import { userAPI } from "../../../api";
import { QUESTION, QUESTION_INTL, TASK, USER_ACTIONS } from "../../../consts";
// Components
import RichTooltip from "../../SharedComponents/RichTooltip";
import AssignmentQuestionAdditionalOptions from "./AssignmentQuestionAdditionalOptions";
import AssignmentQuestionMultiChoice from "./AssignmentQuestionMultiChoice";

import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  ListItemText,
  Paper,
  Select,
  Popover,
  Divider,
  Button
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import AIIcon from "../../SharedComponents/AIIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TooltipWithIntl } from "../../SharedComponents";
import JourneyButtons from "./JourneyButtons";
import HistoryIcon from "@mui/icons-material/History";

// Styles
const useStyles = makeStyles((theme) => ({
  menuBg: {
    "& ul": {},
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    "& .MuiListItemText-root": { lineHeight: "unset" }
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: theme.spacing(3)
  },
  textinputContainer: {
    alignItems: "center"
  },
  pointsCtr: {},
  pntInput: {
    width: "100px",
    height: "42px"
  },
  selectContainer: {
    verticalAlign: "bottom",
    "& .MuiListItemText-root": {
      marginTop: "3px"
    }
  },
  selectItem: {
    lineHeight: "1rem"
  },
  btncontainer: {},
  questionTxt: {
    flexBasis: "100%"
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    lineHeight: "36px",
    borderTop: "1px solid #dedede"
  },
  questionOnly: {
    paddingBlockEnd: theme.spacing(3)
  },
  radio: {
    marginLeft: "20px"
  },
  popover: {
    left: "20px"
  }
}));

function AssignmentQuestion({
  id,
  taskId,
  key,
  quotes = [],
  interaction_subtype = "OPEN_ENDED",
  content,
  concepts,
  taskType,
  points,
  questionIndex,
  options = [],
  readOnly,
  onUpdate,
  onDelete,
  shouldSelect = -1,
  aiQuestion = false,
  regenerateAiQuestion,
  order,
  tempId,
  aiQuestionGenerating = false,
  isLastQuestion,
  onChangeOrder,
  isNewAiQuestion,
  handleNewAiQuestion,
  showErrors,
  setShowErrors,
  generateHighlightConcepts
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const tempIdRef = useRef();
  const initialContent = useRef(content);

  // Redux State
  const text = useSelector(selectCurrentText);

  //Variables
  const questionTypes = [
    QUESTION.OPEN_ENDED,
    QUESTION.FIND_IN_TEXT,
    QUESTION.MULTI_CHOICE
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [questionPoints, setQuestionPoints] = useState(Number(points) || 0);
  const showQuestionTypeTooltip = Boolean(anchorEl);
  const tooltipId = showQuestionTypeTooltip ? "tooltip-popover" : undefined;
  const [questionInput, setQuestionInput] = useState("");
  const [isFocused, setFocused] = useState(false);
  const [questionFinalized, setQuestionFinalized] = useState(false);
  const [questionInputSynced, setQuestionInputSynced] = useState(true);

  const showGenerateHighlightConceptsButton =
    questionInput && !questionInputSynced && questionFinalized;

  const handleTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (content && aiQuestion && isNewAiQuestion && !id) {
      tempIdRef.current = tempId;
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < content.length) {
          setQuestionInput(content.slice(0, i + 1));
          i++;
        } else {
          setQuestionFinalized(true);
          handleNewAiQuestion(questionIndex);
          clearInterval(typingInterval);
        }
      }, 20); // Adjust typing speed here

      return () => clearInterval(typingInterval);
    } else {
      setQuestionInput(content);
      !aiQuestion && setQuestionFinalized(true);
    }
  }, [content]);

  useEffect(() => {
    setQuestionInputSynced(
      questionInput === initialContent.current && questionInput !== ""
    );
    !aiQuestionGenerating &&
      onUpdate(
        {
          quotes: [],
          concepts: []
        },
        tempId
      );
  }, [questionInput]);

  useEffect(() => {
    if (quotes.length > 0) {
      setQuestionInputSynced(true);
    }
  }, [quotes]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" && order !== 0) {
      onChangeOrder(questionIndex, "up");
    } else if (e.key === "ArrowDown" && !isLastQuestion) {
      onChangeOrder(questionIndex, "down");
    }
  };

  const renderTooltipBody = () => {
    return (
      <Box>
        <Typography variant="h6">
          {intl.formatMessage({
            id: QUESTION_INTL.OPEN_ENDED.translationId,
            defaultMessage: QUESTION_INTL.OPEN_ENDED.defaultMessage
          })}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
          {intl.formatMessage({
            id: "task.create.assignment.openEndedTooltip",
            defaultMessage: "Open Ended"
          })}
        </Typography>
        <Typography variant="h6">
          {intl.formatMessage({
            id: QUESTION_INTL.FIND_IN_TEXT.translationId,
            defaultMessage: QUESTION_INTL.FIND_IN_TEXT.defaultMessage
          })}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
          {intl.formatMessage({
            id: "task.create.assignment.findInTextTooltip",
            defaultMessage: "Find in Text"
          })}
        </Typography>
        <Typography variant="h6">
          {intl.formatMessage({
            id: QUESTION_INTL.MULTI_CHOICE.translationId,
            defaultMessage: QUESTION_INTL.MULTI_CHOICE.defaultMessage
          })}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage({
            id: "task.create.assignment.multiChoiceTooltip",
            defaultMessage: "Multi Choice"
          })}
        </Typography>
      </Box>
    );
  };

  const AiBlinkingDots = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        marginLeft: "8px",
        position: "absolute",
        top: "25%",
        transform: "translateY(-50%)"
      }}>
      <span className="dot-1">.</span>
      <span className="dot-2">.</span>
      <span className="dot-3">.</span>
      <style>{`
        @keyframes blink {
          0% {
            opacity: 0;
          }
          33% {
            opacity: 1;
          }
          66% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        .dot-1,
        .dot-2,
        .dot-3 {
          animation: blink 1.4s infinite;
          animation-fill-mode: both;
          font-size: 56px;
          font-family: Roboto;
          line-height: 0.5;
          display: inline-block;
        }
        .dot-2 {
          animation-delay: 0.2s;
        }
        .dot-3 {
          animation-delay: 0.4s;
        }
      `}</style>
    </Box>
  );

  // Render
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        pl: "48px",
        ml: "-48px"
      }}
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      data-testid="assignment-question">
      {isFocused && !readOnly && (
        <Paper
          sx={{
            borderRadius: "12px",
            border: 1,
            borderColor: "rgba(0, 0, 0, 0.23)",
            boxShadow: 0,
            "&:hover": { boxShadow: readOnly ? 0 : 3 },
            width: "40px",
            height: "72px",
            left: "-4px",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px",
            color: "text.secondary"
          }}>
          <TooltipWithIntl
            defaultMessage="Move up"
            intlStringId="task.create.assignment.moveUp"
            placement="top">
            <IconButton
              onClick={() => onChangeOrder(questionIndex, "up")}
              sx={{ "&.MuiButtonBase-root": { paddingBottom: 0 } }}
              disabled={order === 0}>
              <ArrowDropUpIcon />
            </IconButton>
          </TooltipWithIntl>
          <TooltipWithIntl
            defaultMessage="Move down"
            intlStringId="task.create.assignment.moveDown"
            placement="bottom">
            <IconButton
              onClick={() => onChangeOrder(questionIndex, "down")}
              sx={{ "&.MuiButtonBase-root": { paddingTop: 0 } }}
              disabled={isLastQuestion}>
              <ArrowDropDownIcon />
            </IconButton>
          </TooltipWithIntl>
        </Paper>
      )}
      <Paper
        key={key}
        sx={{
          borderRadius: "12px",
          border: 1,
          borderColor: "rgba(0, 0, 0, 0.23)",
          boxShadow: 0,
          "&:hover": { boxShadow: readOnly ? 0 : 3 },
          padding: "24px",
          width: "100%"
        }}
        tabIndex={0}
        role="region"
        onKeyDown={handleKeyDown}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxHeight: "32px"
          }}>
          <Box>
            <Typography
              variant="h6"
              display="inline"
              sx={{ color: "text.secondary" }}>
              {`${intl.formatMessage({
                id:
                  taskType !== TASK.TYPE.GUIDED_READING
                    ? "task.question"
                    : "task.grQuestion",
                defaultMessage: "Question"
              })} ${questionIndex + 1}`}
            </Typography>

            {taskType === "guidedReading" && (
              <Typography
                display="inline"
                sx={{
                  fontSize: "12px",
                  color: "text.secondary",
                  fontWeight: "600"
                }}>
                {`(${intl.formatMessage({
                  id: "task.grQuestionOptional",
                  defaultMessage: "Optional"
                })})`}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", maxHeight: "32px" }}>
            <Box sx={{ display: "flex" }}>
              <IconButton
                onClick={handleTooltipClick}
                sx={{ justifyContent: "flex-end", marginRight: "4px" }}
                color="primary"
                disabled={readOnly}
                aria-label={"Question type"}
                aria-haspopup={"true"}
                aria-expanded={showQuestionTypeTooltip}
                aria-controls={tooltipId}>
                <InfoOutlinedIcon size="small" />
              </IconButton>
              {showQuestionTypeTooltip && (
                <Popover
                  id={tooltipId}
                  open={showQuestionTypeTooltip}
                  anchorEl={anchorEl}
                  onClose={handleTooltipClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  className={classes.popover}>
                  <RichTooltip
                    style={{ width: "300px" }}
                    title="task.create.assignment.questionTypeTooltipTitle"
                    bodyHtml={renderTooltipBody()}
                  />
                </Popover>
              )}
              <Select
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    "&:focus": {
                      borderLeft: "1px solid rgba(0, 0, 0, 0.23)"
                    }
                  },
                  "& .MuiOutlinedInput-root": { paddingLeft: "20px" },
                  width: "200px"
                }}
                aria-label="task-question-type-input"
                id={`questionType${questionIndex}`}
                inputProps={{
                  readOnly: readOnly || taskType === TASK.TYPE.JOURNEY,
                  "aria-label": "task-question-type-input"
                }}
                name="select-question-type"
                className={clsx(classes.selectContainer)}
                value={interaction_subtype}
                dropdownmenuprops={{ classes: { paper: classes.menuBg } }}
                MenuProps={{ classes: { paper: classes.menuBg } }}
                onChange={(e) => {
                  onUpdate(
                    {
                      interaction_subtype: e.target.value,
                      options: ["", "", ""]
                    },
                    tempId
                  );
                }}
                disabled={readOnly || taskType === TASK.TYPE.JOURNEY}
                IconComponent={
                  taskType === TASK.TYPE.JOURNEY ? () => null : undefined
                }>
                {questionTypes.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        onUpdate(
                          {
                            interaction_subtype: item.interaction_subtype,
                            options: ["", "", ""]
                          },
                          tempId
                        );
                      }}
                      value={item.interaction_subtype}>
                      <ListItemText
                        disableTypography={true}
                        className={classes.selectItem}>
                        {intl.formatMessage({
                          id: QUESTION_INTL[item.interaction_subtype]
                            .translationId,
                          defaultMessage:
                            QUESTION_INTL[item.interaction_subtype]
                              .defaultMessage
                        })}
                      </ListItemText>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {aiQuestion && (
              <Box
                sx={{
                  backgroundColor: "rgba(21, 101, 192, 0.3)",
                  width: "56px",
                  height: "56px",
                  top: "-24px",
                  right: "-8px",
                  position: "relative",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  paddingBottom: "8px",
                  marginLeft: "8px"
                }}>
                <AIIcon />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          className={clsx(classes.inputContainer, classes.textinputContainer)}>
          <TextField
            variant="outlined"
            sx={{
              flex: 1,
              "& .MuiInputBase-root": {
                display: "flex",
                alignItems: "flex-start",
                minHeight: "56px",
                position: "relative"
              },
              "& .MuiInputBase-input": {
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
            name="question-formulation"
            aria-label="task-question-formulation-input"
            className={clsx(
              classes.questionTxt,
              interaction_subtype === "PEER_REVIEW" && classes.questionOnly
            )}
            multiline={true}
            minRows={1}
            label={intl.formatMessage({
              id: "task.question.formulation",
              defaultMessage: "Question"
            })}
            placeholder={
              aiQuestionGenerating
                ? ""
                : intl.formatMessage({
                    id: "task.create.assignment.questionPlaceholder",
                    defaultMessage: "Type your question..."
                  })
            }
            value={questionInput || ""}
            onChange={(e) => {
              setQuestionInput(e.target.value);
              setQuestionInputSynced(false);
              onUpdate({ content: e.target.value }, tempId);
            }}
            disabled={readOnly}
            slotProps={{
              input: {
                readOnly: readOnly,
                startAdornment: aiQuestionGenerating ? (
                  <AiBlinkingDots />
                ) : (
                  <></>
                ),
                value: aiQuestionGenerating ? "" : questionInput || ""
              },
              htmlInput: { "data-testid": "task-question-input" }
            }}
            error={showErrors && !questionInput}
            onBlur={() => setShowErrors(false)}
          />
          {aiQuestion && !readOnly && (
            <TooltipWithIntl
              defaultMessage="Regenerate question"
              intlStringId="task.create.assignment.bot.regenerateQuestion"
              placement="left">
              <IconButton
                aria-label="refresh-ai-question"
                sx={{ justifyContent: "flex-end", marginLeft: "24px" }}
                color="primary"
                onClick={() => {
                  userAPI.logAction({
                    action_name: USER_ACTIONS.TASK_REGENERATE_AI_QUESTION,
                    payload: {
                      task_id: taskId
                    }
                  });

                  regenerateAiQuestion(tempId);
                }}>
                <RefreshIcon />
              </IconButton>
            </TooltipWithIntl>
          )}
        </Box>
        {interaction_subtype === "MULTI_CHOICE" && (
          <AssignmentQuestionMultiChoice
            options={options}
            shouldSelect={shouldSelect}
            readOnly={readOnly}
            onUpdate={onUpdate}
            tempId={tempId}
          />
        )}
        <Box className={clsx(classes.inputContainer)}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
              width: "100%",
              maxWidth: "calc(100% - 264px)"
            }}>
            {taskType === TASK.TYPE.JOURNEY ? (
              showGenerateHighlightConceptsButton && (
                <Button
                  variant="outlined"
                  sx={{ minHeight: "37px" }}
                  onClick={() => generateHighlightConcepts(tempId)}>
                  <HistoryIcon sx={{ marginRight: "8px" }} />
                  {intl.formatMessage({
                    id: "task.create.assignment.journey.generateHighlightConceptsButton",
                    defaultMessage: "Generate Highlights and Concepts"
                  })}
                </Button>
              )
            ) : (
              <AssignmentQuestionAdditionalOptions
                showDefineKeyConcepts={true}
                text={text}
                readOnly={readOnly}
                highlights={quotes}
                interactionSubtype={interaction_subtype}
                onUpdate={onUpdate}
                tempId={tempId}
                concepts={concepts}
                onConcepts={(concepts) => {
                  onUpdate({ concepts: concepts }, tempId);
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            <Box
              className={classes.wordLimit}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "8px",
                width: "100%"
              }}>
              <TextField
                sx={{ maxWidth: "100px", width: "100px" }}
                variant="outlined"
                id={`points${questionIndex}`}
                aria-label="task-points-input"
                value={questionPoints}
                className={classes.pntInput}
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  if (value < 0 || isNaN(value)) value = 0;
                  setQuestionPoints(Number(value));
                  onUpdate({ points: Number(value) }, tempId);
                }}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                label={intl.formatMessage({
                  id: "task.questions.points",
                  defaultMessage: "Points"
                })}
                disabled={readOnly}
                slotProps={{
                  input: {
                    readOnly: readOnly,
                    style: { height: "36px" }
                  },

                  inputLabel: { shrink: true }
                }}
              />
            </Box>
            <IconButton
              onClick={onDelete}
              sx={{ marginLeft: "24px", justifyContent: "flex-end" }}
              aria-label="delete question"
              disabled={readOnly}
              color="primary"
              data-testid="delete-question">
              <DeleteIcon size="small" />
            </IconButton>
          </Box>
        </Box>
        {taskType === TASK.TYPE.JOURNEY && (
          <Box sx={{ gap: "16px", display: "flex", flexDirection: "column" }}>
            <Divider sx={{ marginTop: "16px" }} />
            <Typography variant="body1">
              {intl.formatMessage({
                id: "task.create.assignment.journey.title",
                defaultMessage: "Your students will getush"
              })}
            </Typography>
            <JourneyButtons
              quotes={quotes}
              onQuotes={(quotes) => {
                onUpdate({ quotes: quotes }, tempId);
              }}
              concepts={concepts}
              onConcepts={(concepts) => {
                onUpdate({ concepts: concepts }, tempId);
              }}
              disabled={questionFinalized && !questionInputSynced}
              error={showErrors && quotes.length === 0}
              blink={!questionFinalized}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}

AssignmentQuestion.propTypes = {
  id: PropTypes.string,
  question: PropTypes.object,
  quotes: PropTypes.array,
  concepts: PropTypes.array,
  type: PropTypes.number,
  points: PropTypes.number,
  questionIndex: PropTypes.number,
  options: PropTypes.array,
  text: PropTypes.object,
  showType: PropTypes.bool,
  showPoints: PropTypes.bool,
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  key: PropTypes.number,
  aiQuestion: PropTypes.bool,
  regenerateAiQuestion: PropTypes.func,
  order: PropTypes.number,
  tempId: PropTypes.string,
  aiQuestionGenerating: PropTypes.bool,
  isLastQuestion: PropTypes.bool,
  onChangeOrder: PropTypes.func,
  isNewAiQuestion: PropTypes.bool,
  handleNewAiQuestion: PropTypes.func,
  showErrors: PropTypes.bool,
  setShowErrors: PropTypes.func
};

export default AssignmentQuestion;
