import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../../../redux/pdfSlice";
import { scrollPageIntoView } from "../../../../reader/pdf/utils";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";
import { TextField, Box, Typography } from "@mui/material";
import DirectionalChevronIcon from "../../../../SharedComponents/DirectionalChevronIcon";
import HeaderButtonConstructor from "../../../../SharedComponents/HeadeButtonConstructor";

const useStyles = makeStyles((theme) => ({
  barIcon: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  },
  pageNumbering: {
    flexDirection: "row",
    justifyContent: "center",
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      color: "#FFFFFF",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  pageNavInput: {
    width: "30px",
    "&:hover": {
      borderBottom: `1px solid white`,
      textDecoration: "none",
      "-webkit-appearance": "none"
    },
    "&:active": {
      borderBottom: `1px solid white`,
      textDecoration: "none"
    },
    "& input": {
      borderBottom: `1px solid #979797`,
      "&:focus": {
        borderBottom: `1px solid white`
      },
      backgroundColor: "transparent",
      textDecorationColor: "white",
      textAlign: "end",
      maxHeight: "50px",
      "-moz-appearance": "textfield",
      " -webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      padding: 0,
      fontSize: "16px"
    }
  },
  totalPages: {
    display: "inline-block",
    marginLeft: theme.spacing(0.5)
  },
  isCompatReaderActionButton: {
    height: "30px",
    width: "30px"
  }
}));

const PdfPageNavigation = ({ contentWidth }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const totalPages = useSelector((state) => state.pdf.totalPages);
  const currentPage = useSelector((state) => state.pdf.currentPage);

  const [isCompact, setIsCompact] = useState(false);
  useEffect(() => {
    setIsCompact(contentWidth < 660);
  }, [contentWidth]);

  return (
    <>
      <Box>
        <HeaderButtonConstructor
          intlStringId="pdf.previousPage"
          defaultMessage="Previous page"
          placement="bottom"
          iconLabel="Previous page"
          disabled={currentPage === 1}
          className={clsx(
            classes.barIcon,
            isCompact && classes.isCompatReaderActionButton
          )}
          handleClick={() => {
            const options = {
              behavior: "smooth",
              block: "start"
            };
            scrollPageIntoView(currentPage - 1, options);
          }}
          icon={<DirectionalChevronIcon reverse />}
        />
      </Box>
      <Typography component="span" className={classes.pageNumbering}>
        <TextField
          placeholder={"current page"}
          variant="standard"
          className={clsx(classes.pageNavInput)}
          id="pdf-current-page-nav-input"
          aria-label="current-page-input"
          type="number"
          sx={{
            "& .Mui-focused": {
              outline: "2px solid white"
            }
          }}
          value={currentPage}
          onChange={(e) => {
            const options = {
              inline: "start",
              block: "start"
            };
            const pageNumber = Number(e.target.value);
            dispatch(setCurrentPage(pageNumber));
            scrollPageIntoView(pageNumber, options);
          }}
          slotProps={{
            input: { disableUnderline: true }
          }}
        />
        <Typography component="span" className={classes.totalPages}>
          {" / "}
        </Typography>
        <Typography component="span" className={classes.totalPages}>
          {totalPages || " "}
        </Typography>
      </Typography>
      <Box>
        <HeaderButtonConstructor
          intlStringId="pdf.nextPage"
          defaultMessage="Next page"
          placement="bottom"
          iconLabel="next Page"
          disabled={currentPage === totalPages}
          className={clsx(
            classes.barIcon,
            isCompact && classes.isCompatReaderActionButton
          )}
          handleClick={() => {
            const options = {
              behavior: "smooth",
              block: "start"
            };
            scrollPageIntoView(currentPage + 1, options);
          }}
          icon={<DirectionalChevronIcon />}
        />
      </Box>
    </>
  );
};

export default PdfPageNavigation;
