// RightPanel.jsx
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import RightSideButtonGroup from "./RightPanel/RightSideButtonGroup.jsx";
import NewCommentPanel from "./RightPanel/NewCommentPanel.jsx";
import NewChat from "./RightPanel/NewChat.jsx";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      minWidth: "40px",
      backgroundColor: theme.palette.grey.paper,
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column"
    },
    content: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      minHeight: 0
    },
    header: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.border,
      paddingBlock: "6px",
      paddingInline: "10px",
      position: "sticky",
      flexShrink: 0,
      color: theme.palette.text.secondary
    }
  };
});

const RightPanel = ({ isCollapsed, onToggleCollapse }) => {
  const classes = useStyles();
  const [view, setView] = useState(isCollapsed ? null : "chat");

  const onViewClick = (viewClicked) => {
    if (!isCollapsed && view === viewClicked) {
      onToggleCollapse();
      setView(null);
    } else {
      isCollapsed && onToggleCollapse();
      setView(viewClicked);
    }
  };

  return isCollapsed ? (
    <RightSideButtonGroup
      view={view}
      onViewClick={onViewClick}
      isHorizontal={false}
      toolTipPlacement={"left"}
    />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography
          sx={{ fontSize: "0.75rem", marginInline: "10px" }}
          component="h4">
          {view === "chat" ? "ACADEMIC COACH" : "COMMENTS"}
        </Typography>
        <RightSideButtonGroup
          view={view}
          onViewClick={onViewClick}
          isHorizontal={true}
        />
      </Box>
      <Box className={classes.content}>
        {view === "chat" ? <NewChat /> : <NewCommentPanel />}
      </Box>
    </Box>
  );
};

export default RightPanel;
