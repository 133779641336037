import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPdfZoom } from "../../../../../redux/pdfSlice";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";

import { Box } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import HeaderButtonConstructor from "../../../../SharedComponents/HeadeButtonConstructor";
import { useGetTheme } from "../../../../../hooks";

const useStyles = makeStyles((theme) => ({
  barIcon: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  },
  isCompatReaderActionButton: {
    height: "30px",
    width: "30px"
  },
  isCompactContainer: {
    marginInlineEnd: theme.spacing(1)
  }
}));
const PdfActionBar = ({ contentWidth }) => {
  const classes = useStyles();
  const theme = useGetTheme();
  const dispatch = useDispatch();

  const zoom = useSelector((state) => state.pdf.zoom);
  const [isCompact, setIsCompact] = useState(false);

  // Check if the content panel is narrow and switch to compact mode
  useEffect(() => {
    setIsCompact(contentWidth < 660);
  }, [contentWidth]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingInlineEnd: theme.spacing(0.5)
      }}
      className={isCompact && classes.isCompactContainer}>
      <Box>
        <Box>
          <HeaderButtonConstructor
            intlStringId="pdf.zoomIn"
            defaultMessage="Zoom In"
            placement="bottom"
            iconLabel="zoomIn"
            className={clsx(
              classes.barIcon,
              isCompact && classes.isCompatReaderActionButton
            )}
            handleClick={() => {
              dispatch(setPdfZoom(zoom + 0.1));
            }}
            icon={<ZoomInIcon />}
          />
        </Box>
      </Box>
      <Box>
        <HeaderButtonConstructor
          intlStringId="pdf.zoomOut"
          defaultMessage="Zoom out"
          placement="bottom"
          iconLabel="zoom out"
          className={clsx(
            classes.barIcon,
            isCompact && classes.isCompatReaderActionButton
          )}
          handleClick={() => {
            if (zoom > 0.1) {
              dispatch(setPdfZoom(zoom - 0.1));
            }
          }}
          icon={<ZoomOutIcon />}
        />
      </Box>
    </Box>
  );
};

export default PdfActionBar;
