// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { taskShape, submissionShape } from "../../../utils/propTypes";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { SUBMISSION_STATUS, USER_TYPE } from "../../../consts";
import { calculateSubmissionStatus } from "./utills";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  chip: {
    cursor: "pointer"
  },
  chipLink: {
    cursor: "pointer"
  },
  missed: {
    color: theme.palette.contrast,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.error.main}`,
    "&:hover": { backgroundColor: theme.palette.error.dark, color: "#FFF" },
    "&:focus": { backgroundColor: theme.palette.error.dark, color: "#FFF" },
    "&:active": { backgroundColor: theme.palette.error.main }
  },
  late: {
    color: theme.palette.warning.contrast,
    backgroundColor: theme.palette.warning.main,
    "&:hover": { backgroundColor: theme.palette.warning.dark },
    "&:focus": { backgroundColor: theme.palette.warning.dark },
    "&:active": { backgroundColor: theme.palette.warning.main }
  },
  pending: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  latePending: {
    color: theme.palette.warning.main,
    border: `1px solid ${theme.palette.warning.main}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  onTime: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": { backgroundColor: theme.palette.secondary.dark },
    "&:focus": { backgroundColor: theme.palette.secondary.dark },
    "&:active": { backgroundColor: theme.palette.secondary.main }
  },
  sent: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.primary.main,
    "&:hover": { backgroundColor: theme.palette.primary.dark },
    "&:focus": { backgroundColor: theme.palette.primary.dark },
    "&:active": { backgroundColor: theme.palette.primary.main }
  },
  add: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "transparent",
    "&:hover": { backgroundColor: theme.palette.text.p4 },
    "&:focus": { backgroundColor: theme.palette.text.p4 }
  },
  reviewed: {
    backgroundColor: "#BDBDBD",
    color: "currentCollor"
  },
  teacherView: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    "&:hover": { backgroundColor: theme.palette.primary.main },
    "&:focus": { backgroundColor: theme.palette.primary.main }
  }
}));

export default function TaskStatusChip({
  task,
  submission,
  userRole,
  onClick
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral State
  const [label, setLabel] = useState("");
  const [chipStyle, setChipStyle] = useState("");

  // Variables
  const submitted = intl.formatMessage({
    id: "task.status.submitted",
    defaultMessage: "Submitted"
  });
  const missed = intl.formatMessage({
    id: "task.status.passed",
    defaultMessage: "Passed deadline"
  });
  const pending = intl.formatMessage({
    id: "task.status.pending",
    defaultMessage: "Pending"
  });
  const studentView = intl.formatMessage({
    id: "task.status.studentView",
    defaultMessage: "Student View"
  });

  //Derived State
  const isTeacher =
    (submission.role || userRole)?.toUpperCase() ===
    USER_TYPE.TEACHER.toUpperCase();

  const submissionStatus = calculateSubmissionStatus(
    submission.due_date,
    submission.submission_date,
    task.original_due_date
  );

  // Handle click with role-specific logic if needed
  const handleClick = (event) => {
    // Call the provided onClick handler with information about the teacher role
    if (onClick) {
      onClick(event, isTeacher);
    }
  };

  // Behavior
  useEffect(() => {
    // Set the style and label based on role and status
    if (isTeacher) {
      setChipStyle("teacherView");
      setLabel(studentView);
    } else {
      // Student view styling
      switch (submissionStatus) {
        case SUBMISSION_STATUS.MISSED:
          setChipStyle("missed");
          setLabel(missed);
          break;

        case SUBMISSION_STATUS.LATE_PENDING:
          setChipStyle("latePending");
          setLabel(pending);
          break;

        case SUBMISSION_STATUS.LATE:
          setChipStyle("late");
          setLabel(submitted);
          break;

        case SUBMISSION_STATUS.ON_TIME:
          setChipStyle("onTime");
          setLabel(submitted);
          break;

        default:
          setChipStyle("pending");
          setLabel(pending);
      }
    }
  }, [isTeacher, missed, pending, submissionStatus, submitted, studentView]);

  // Render
  return (
    <Chip
      className={clsx(
        classes.chip,
        chipStyle === "late" && classes.late,
        chipStyle === "missed" && classes.missed,
        chipStyle === "latePending" && classes.latePending,
        chipStyle === "pending" && classes.pending,
        chipStyle === "onTime" && classes.onTime,
        chipStyle === "sent" && classes.sent,
        chipStyle === "teacherView" && classes.teacherView
      )}
      label={label}
      size="small"
      onClick={handleClick}
      clickable={Boolean(onClick)}
    />
  );
}

TaskStatusChip.propTypes = {
  task: PropTypes.shape(taskShape),
  submission: PropTypes.shape(submissionShape),
  userRole: PropTypes.string.isRequired,
  onClick: PropTypes.func
};
