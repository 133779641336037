// src/utils/useDeviceDetection.js
import { useState, useEffect } from "react";

/**
 * Enhanced device detection hook that combines categorical and boolean approaches
 * with built-in optimizations for different platforms
 *
 * @returns {Object} Comprehensive device information and optimization utilities
 */
function useDeviceDetect() {
  // Initialize with default state
  const [deviceInfo, setDeviceInfo] = useState({
    // Boolean flags for specific device/platform detection
    isIPad: false,
    isIPhone: false,
    isIOS: false,
    isAndroid: false,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isTouchDevice: false,
    isWindows: false,
    isMac: false,
    isLinux: false,

    // Categorical information
    deviceType: "unknown", // "mobile", "tablet", "desktop"
    specificDevice: "unknown", // "iPhone", "iPad", "Android Phone", etc.

    // Display information
    screenWidth: 0,
    screenHeight: 0,
    orientation: "unknown", // "portrait" or "landscape"

    // User agent for debugging
    userAgent: ""
  });

  useEffect(() => {
    // Skip if not in browser environment (SSR compatibility)
    if (typeof window === "undefined") return;

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    // Detect specific platforms and devices
    const isIPad =
      /iPad/.test(userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    const isIPhone = /iPhone/.test(userAgent);
    const isIOS = isIPad || isIPhone || /iPod/.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);
    const isWindows = /Win32|Win64|Windows|WinCE/i.test(userAgent);
    const isMac =
      /Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent) && !isIPad;
    const isLinux = /Linux/i.test(userAgent) && !isAndroid;

    // Detect touch capabilities
    const isTouchDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;

    // Tablet detection is tricky, use combination of UA and screen size
    const isTablet =
      isIPad ||
      /tablet|playbook|silk|android(?!.*mobile)/i.test(userAgent) ||
      (isAndroid && Math.min(width, height) > 768);

    // General mobile detection
    const isMobileUA =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    // Determine overall device type
    const isMobile = isMobileUA && !isTablet;

    let deviceType = "desktop";
    if (isMobile) deviceType = "mobile";
    else if (isTablet) deviceType = "tablet";

    // Determine specific device with more granular information
    let specificDevice = "unknown";

    if (isIPhone) specificDevice = "iPhone";
    else if (isIPad) specificDevice = "iPad";
    else if (isAndroid && isMobile) specificDevice = "Android Phone";
    else if (isAndroid && isTablet) specificDevice = "Android Tablet";
    else if (isMac) specificDevice = "Mac";
    else if (isWindows) specificDevice = "Windows";
    else if (isLinux) specificDevice = "Linux";

    // Set orientation based on screen dimensions
    const orientation = width > height ? "landscape" : "portrait";

    // Update device information state
    setDeviceInfo({
      isIPad,
      isIPhone,
      isIOS,
      isAndroid,
      isMobile,
      isTablet,
      isDesktop: !isMobile && !isTablet,
      isTouchDevice,
      isWindows,
      isMac,
      isLinux,
      deviceType,
      specificDevice,
      screenWidth: width,
      screenHeight: height,
      orientation,
      userAgent
    });

    // Apply iOS/iPad-specific optimizations
    if (isIOS) {
      const style = document.createElement("style");
      style.innerHTML = `
        * {
          -webkit-tap-highlight-color: transparent;
          touch-action: manipulation;
        }
        
        .MuiButtonBase-root,
        [role="button"],
        button,
        a {
          cursor: pointer !important;
          touch-action: manipulation;
        }
      `;
      document.head.appendChild(style);

      // Clean up on unmount
      return () => {
        if (document.head.contains(style)) {
          document.head.removeChild(style);
        }
      };
    }
  }, []);

  /**
   * Creates an optimized event handler for the detected device
   * Particularly useful for improving touch events on iOS
   *
   * @param {Function} callback - The function to call on click/touch
   * @param {Object} options - Configuration options
   * @param {boolean} options.preventDefault - Whether to prevent default behavior
   * @param {number} options.iosDelay - Delay in ms for iOS devices
   * @returns {Function} An optimized event handler
   */
  const createOptimizedHandler = (callback, options = {}) => {
    const { preventDefault = true, iosDelay = 10 } = options;

    return (e) => {
      // Prevent default when requested
      if (preventDefault && e && e.preventDefault) {
        e.preventDefault();
      }

      // For iOS devices, use a slight delay to ensure touch registers properly
      if (deviceInfo.isIOS) {
        setTimeout(() => {
          callback(e);
        }, iosDelay);
      } else {
        // For non-iOS devices, execute immediately
        callback(e);
      }
    };
  };

  /**
   * Gets appropriate event handlers based on device type
   * Useful for components that need different events for touch vs. mouse
   *
   * @returns {Object} Device-appropriate event handlers
   */
  const getEventHandlers = () => {
    if (deviceInfo.isTouchDevice) {
      return {
        eventStart: "touchstart",
        eventMove: "touchmove",
        eventEnd: "touchend"
      };
    }
    return {
      eventStart: "mousedown",
      eventMove: "mousemove",
      eventEnd: "mouseup"
    };
  };

  /**
   * Apply responsive styles based on device type
   * @param {Object} styles - Object containing style objects keyed by device type
   * @returns {Object} The appropriate style object for current device
   */
  const getResponsiveStyles = (styles) => {
    if (!styles) return {};

    const { mobile, tablet, desktop, ...baseStyles } = styles;

    let deviceStyles = {};
    if (deviceInfo.isMobile && mobile) deviceStyles = mobile;
    else if (deviceInfo.isTablet && tablet) deviceStyles = tablet;
    else if (deviceInfo.isDesktop && desktop) deviceStyles = desktop;

    return { ...baseStyles, ...deviceStyles };
  };

  // Return the combined enhanced API
  return {
    ...deviceInfo,
    createOptimizedHandler,
    getEventHandlers,
    getResponsiveStyles
  };
}

export default useDeviceDetect;
