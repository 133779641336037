// LeftPanel.jsx
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AnswerMode from "./LeftPanel/AnswerMode";
import { useQuery } from "../../../hooks";
import { useSelector } from "react-redux";
import { selectSubmission, selectTask } from "../../../redux/tasksSlice";
import { selectQuestions } from "../../../redux/interactionsSlice";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { interactionsAPI, standardTaskAPI } from "../../../api";
import QuestionList from "./LeftPanel/QuestionsList";

// Panel modes
export const PANEL_MODES = {
  HIGHLIGHTS: "highlights",
  ANSWER: "answer"
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      minWidth: "40px",
      position: "relative",
      backgroundColor: theme.palette.grey.paper,
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden"
    },
    header: {
      padding: theme.spacing(2),
      height: "48px",
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    instructionsHeader: {
      minHeight: "146px",
      paddingBlockStart: theme.spacing(1),
      borderBottom: `2px solid ${theme.palette.divider}`
    },
    instructions: {
      paddingBlockEnd: theme.spacing(2),
      paddingInline: theme.spacing(1.5),
      color: theme.palette.text.secondary,
      fontSize: "0.875rem"
    },
    title: {
      fontWeight: 500,
      paddingInlineStart: theme.spacing(1.5),
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
      fontSize: "0.75rem",
      letterSpacing: "0.1em"
    },
    content: {
      flex: 1,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden"
    },
    collapseButton: {
      height: "36px",
      width: "36px",
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
      "&:hover": {
        backgroundColor: "#f0f0f0"
      }
    },
    collapsedContent: {
      writingMode: "vertical-rl",
      textOrientation: "mixed",
      transform: "rotate(180deg)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "16px 0"
    },
    panelContent: {
      flex: 1,
      overflow: "auto",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    }
  };
});

// Import QuestionCard, SortableQuestionCard, and QuestionList components

const LeftPanel = ({
  isCollapsed,
  onToggleCollapse,
  activeMode,
  handleModeChange
  // combinedViewMode,
  // setCombinedViewMode
}) => {
  const classes = useStyles();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { submission_id } = useQuery();

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );

  const task = useSelector((state) =>
    selectTask(state, Number(submission.task_id))
  );

  const questions = useSelector((state) =>
    selectQuestions(state, submission.task_id)
  );

  const highlights = useSelector((state) => state.interactions.highlights);
  const answers = useSelector((state) => state.interactions.answers);
  const selectedQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );

  // Set up DnD sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  // Split questions into instructor and student questions
  const instructorQuestions = questions.filter(
    (q) => q.user_type === "TEACHER"
  );
  const studentQuestions = questions.filter((q) => q.user_type !== "TEACHER");

  // Handle question selection
  const handleQuestionSelect = (question) => {
    if (question.task_id) {
      standardTaskAPI.updateSelectedQuestionId(question.id, submission.task_id);
    } else {
      standardTaskAPI.updateSelectedQuestionId(question.id);
    }
  };

  // Handle answer click
  const handleAnswerClick = (question) => {
    // Switch to answer mode if we're not already in it
    if (activeMode !== PANEL_MODES.ANSWER) {
      handleModeChange(PANEL_MODES.ANSWER);
    }

    // Select the question
    handleQuestionSelect(question);
  };

  // Handle sorting questions
  const handleSortQuestions = (activeQuestionId, overQuestionIdOrDirection) => {
    const currentOrder = studentQuestions.map((q) => q.id);
    const activeIndex = currentOrder.indexOf(activeQuestionId);

    // Handle keyboard-based movement
    if (typeof overQuestionIdOrDirection === "number") {
      const newIndex = activeIndex + overQuestionIdOrDirection;
      if (newIndex < 0 || newIndex >= currentOrder.length) {
        return; // Prevent out-of-bounds movement
      }
      const order = [...currentOrder];
      const [movedQuestion] = order.splice(activeIndex, 1);
      order.splice(newIndex, 0, movedQuestion);
      interactionsAPI.updateQuestionOrder(order, currentOrder);
      return;
    }

    // Handle mouse-based drag-and-drop
    if (typeof overQuestionIdOrDirection === "string") {
      const overIndex = currentOrder.indexOf(overQuestionIdOrDirection);
      if (overIndex === -1) {
        return; // Ensure overQuestionId is valid
      }
      const order = [...currentOrder];
      order.splice(activeIndex, 1);
      order.splice(overIndex, 0, activeQuestionId);
      interactionsAPI.updateQuestionOrder(order, currentOrder);
    }
  };

  // Handle drag end event
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      // Extract the question ID from the sortable ID
      const activeId = active.id.replace("sort-student-questions-", "");
      const overId = over.id.replace("sort-student-questions-", "");

      handleSortQuestions(activeId, overId);
    }
  };

  return (
    <Box className={classes.container}>
      {isCollapsed ? (
        <Box>
          <IconButton
            onClick={onToggleCollapse}
            className={classes.collapseButton}
            size="small">
            {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <Box className={classes.collapsedContent}>Assignment</Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
          <Box className={classes.header}>
            <IconButton
              onClick={onToggleCollapse}
              className={classes.collapseButton}
              size="small">
              {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            {/* Header with title */}
            <Box>
              <Typography className={classes.title}>Assignment</Typography>
            </Box>
          </Box>
          <Box className={classes.instructionsHeader}>
            <Typography variant="h5" sx={{ fontSize: "24px", padding: "12px" }}>
              {task?.name}
            </Typography>
            <Box className={classes.instructions}>
              <Typography variant="body2">
                Select a question, highlight supporting evidence in the text
                then answer according to your findings.{" "}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.content}>
            {/* Panel Content */}
            <Box className={classes.panelContent}>
              {activeMode === PANEL_MODES.HIGHLIGHTS ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}>
                  <QuestionList
                    instructorQuestions={instructorQuestions}
                    studentQuestions={studentQuestions}
                    highlights={highlights}
                    answers={answers}
                    selectedQuestionId={selectedQuestionId}
                    onQuestionSelect={handleQuestionSelect}
                    onAnswerClick={handleAnswerClick}
                  />
                </DndContext>
              ) : (
                <AnswerMode
                  questions={questions}
                  currentQuestionIndex={currentQuestionIndex}
                  onQuestionChange={setCurrentQuestionIndex}
                  task={task}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LeftPanel;
