import React, { useState } from "react";
import { Box, Popover, IconButton, Button, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useIntl } from "react-intl";
import makeStyles from "@mui/styles/makeStyles";
import { Arrow } from "react-laag";

// Styles
const useStyles = makeStyles(() => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    zIndex: 1500,
    padding: "12px 16px",
    width: "250px",
    fontSize: "14px",
    backgroundColor: "#F2F2F5"
  }
}));

export const RichTooltipPopover = React.forwardRef(function RichTooltipPopover(
  {
    name,
    readOnly,
    title,
    body,
    bodyHtml,
    action,
    onActionClick = () => {},
    layerProps = {},
    arrowProps,
    style = {},
    triggerElement = "info"
  },
  ref
) {
  const classes = useStyles();
  const intl = useIntl();
  layerProps.style = { ...layerProps.style, ...style };

  const id = `${name ? `${name.toLowerCase().split(" ").join("-")}-` : ""}tooltip`;
  const [anchorEl, setAnchorEl] = useState(null);
  const showTooltip = Boolean(anchorEl);
  const tooltipId = showTooltip ? "tooltip-popover" : undefined;

  const handleTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box onClick={(event) => !readOnly && handleTooltipClick(event)}>
        {triggerElement === "info" ? (
          <IconButton color="primary" disabled={!!readOnly} aria-label={name}>
            <InfoOutlinedIcon size="small" />
          </IconButton>
        ) : (
          triggerElement
        )}
      </Box>

      {showTooltip && (
        <Popover
          id={tooltipId}
          open={showTooltip}
          anchorEl={anchorEl}
          onClose={() => handleTooltipClose(setAnchorEl)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          aria-labelledby={id}
          slotProps={{
            paper: {
              sx: {
                boxShadow: 3,
                borderRadius: "12px"
              }
            }
          }}>
          <Box {...layerProps} className={classes.tooltip} id={id}>
            {title && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginBottom: "4px"
                }}>
                {intl.formatMessage({
                  id: title,
                  defaultMessage: "Title"
                })}
              </Typography>
            )}
            {body && (
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px"
                }}>
                {intl.formatMessage({
                  id: body,
                  defaultMessage: "Body"
                })}
              </Typography>
            )}
            {bodyHtml && bodyHtml}
            {action && (
              <Button
                sx={{
                  marginTop: 2,
                  padding: 0,
                  justifyContent: "flex-start",
                  minWidth: "inherit"
                }}
                ref={ref}
                variant="text"
                onClick={onActionClick}>
                {intl.formatMessage({
                  id: action,
                  defaultMessage: "Action"
                })}
              </Button>
            )}
            {arrowProps && <Arrow {...arrowProps} />}
          </Box>
        </Popover>
      )}
    </Box>
  );
});
