// Dependencies
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Divider,
  Typography,
  IconButton,
  Popover,
  FormControlLabel,
  Checkbox,
  Select,
  FormControl,
  MenuItem,
  InputLabel
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RichTooltip from "../../SharedComponents/RichTooltip";
import { setTaskCreationStates } from "../../../redux/tasksSlice";
import { TASK } from "../../../consts";

//Styles
const useStyles = makeStyles(() => ({
  section: {
    marginBottom: "50px"
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: "8px",
    marginTop: "8px"
  },
  popover: {
    left: "20px"
  },
  coach: {
    display: "flex",
    justifyContent: "flex-start"
  }
}));

function AssignmentAdvancedSettings({
  readOnly,
  isEdit,
  botMode,
  setBotMode,
  lmsSyncDisabled,
  setLmsSyncDisabled,
  setIsDirty,
  taskType
}) {
  //Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  // Variables
  const [acadmeicCoachAnchorEl, setAcademicCoachAnchorEl] = useState(null);
  const showAcademicCoachTooltip = Boolean(acadmeicCoachAnchorEl);
  const acadmeicCoachTooltipId = showAcademicCoachTooltip
    ? "academic-coach-tooltip-popover"
    : undefined;

  const handleTooltipClick = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };
  const handleTooltipClose = (setAnchor) => {
    setAnchor(null);
  };

  const renderAcademicCoachTooltipBody = () => {
    return (
      <Box>
        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
          {intl.formatMessage({
            id: "task.create.readings.academicCoachTooltipCoach",
            defaultMessage: "Academic Coach"
          })}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage({
            id: "task.create.readings.academicCoachTooltipGeneration",
            defaultMessage: "Academic Coach"
          })}
        </Typography>
      </Box>
    );
  };

  //Render
  return (
    <Box>
      <Box className={classes.section}>
        <Typography variant="h6" component="h2">
          {intl.formatMessage({
            id: "task.create.readings.lmsQuestion",
            defaultMessage: "Sync Assignment with LMS?"
          })}
        </Typography>
        <Divider className={classes.divider} />
        <Box className={clsx(classes.coach, classes.sectionContent)}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!lmsSyncDisabled}
                disabled={readOnly || isEdit}
                onChange={(event) => {
                  setLmsSyncDisabled(!event.target.checked);
                  dispatch(
                    setTaskCreationStates(
                      "taskCreationLmsSync",
                      !event.target.checked
                    )
                  );
                  setIsDirty(true);
                }}
                inputProps={{
                  "aria-checked": !lmsSyncDisabled,
                  "aria-labelledby": "lms-sync-checkbox-label"
                }}
              />
            }
            label={
              <span id="lms-sync-checkbox-label">
                {intl.formatMessage({
                  id: "task.create.readings.showAssignmentOnLMS",
                  defaultMessage:
                    "This will display the assignment in your course's LMS and sync student grades automatically"
                })}
              </span>
            }
          />
        </Box>
      </Box>
      {taskType !== TASK.TYPE.JOURNEY && (
        <Box className={classes.section}>
          <Typography variant="h6" component="h2">
            {intl.formatMessage({
              id: "task.create.readings.coachQuestion",
              defaultMessage: "Enable academic coach chatbot for your students?"
            })}
          </Typography>
          <Divider className={classes.divider} />
          <Box
            className={clsx(classes.coach, classes.sectionContent)}
            sx={{ display: "flex", alignItems: "center", marginTop: "24px" }}>
            <FormControl sx={{ width: "350px" }}>
              <InputLabel id="taskType-label">
                {intl.formatMessage({
                  id: "task.create.readings.academicCoachMode",
                  defaultMessage: "Academic coach mode"
                })}
              </InputLabel>
              <Select
                labelId="taskType-label"
                variant="outlined"
                id={`taskType`}
                aria-label="Academic coach mode"
                disabled={readOnly}
                label={intl.formatMessage({
                  id: "task.create.readings.academicCoachMode",
                  defaultMessage: "Academic coach mode"
                })}
                value={botMode}
                onChange={(event) => {
                  setBotMode(event.target.value);
                  dispatch(
                    setTaskCreationStates(
                      "taskCreationBotMode",
                      event.target.value
                    )
                  );
                  setIsDirty(true);
                }}>
                <MenuItem value="disabled">
                  {intl.formatMessage({
                    id: "task.create.readings.academicCoachDisable",
                    defaultMessage: "Disable"
                  })}
                </MenuItem>
                <MenuItem value="full">
                  {intl.formatMessage({
                    id: "task.create.readings.academicCoachFull",
                    defaultMessage: "Enable with generated answers"
                  })}
                </MenuItem>
                <MenuItem value="chat">
                  {intl.formatMessage({
                    id: "task.create.readings.academicCoachChat",
                    defaultMessage: "Enable without generated answers"
                  })}
                </MenuItem>
              </Select>
            </FormControl>
            {!readOnly && (
              <IconButton
                color="primary"
                disabled={readOnly}
                onClick={(event) =>
                  handleTooltipClick(event, setAcademicCoachAnchorEl)
                }
                aria-label="Academic coach info">
                <InfoOutlinedIcon size="small" />
              </IconButton>
            )}
            {showAcademicCoachTooltip && (
              <Popover
                id={acadmeicCoachTooltipId}
                open={showAcademicCoachTooltip}
                anchorEl={acadmeicCoachAnchorEl}
                onClose={() => handleTooltipClose(setAcademicCoachAnchorEl)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                className={classes.popover}
                aria-labelledby="academic-coach-tooltip">
                <RichTooltip
                  id="academic-coach-tooltip"
                  style={{ width: "300px" }}
                  title="task.create.readings.academicCoach"
                  bodyHtml={renderAcademicCoachTooltipBody()}
                />
              </Popover>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

AssignmentAdvancedSettings.propTypes = {
  readOnly: PropTypes.bool,
  isEdit: PropTypes.bool,
  botMode: PropTypes.string,
  setBotMode: PropTypes.func,
  lmsSyncDisabled: PropTypes.bool,
  setLmsSyncDisabled: PropTypes.func,
  setIsDirty: PropTypes.func
};

export default AssignmentAdvancedSettings;
